/**
 * useInterestedRelativeAct
 */

import api from "@/api";
import { MessageAlert } from "./useMessageAlert";

export const useInterestedRelativeAct = () => {
    /**
     * get interested list
     * @returns
     */
    const _fetchAllInterestedListByApi = async () => {
        try {
            const res = await api.getInterestedList();
            return Promise.resolve(res);
        } catch (error: any) {
            const errMess = error?.message || "fetch interested list failed";
            MessageAlert(errMess);
        }
    };

    /**
     * set interested
     * @param params
     * @returns
     */
    const _setAddInterestedByApi = async (params: {
        memberId?: string;
        interestedId?: string;
    }) => {
        try {
            const res = await api.setUserInterested(params);
            return Promise.resolve(res);
        } catch (error: any) {
            const errMess = error?.message || "set interested failed";
            MessageAlert(errMess);
        }
    };

    const _fetchAllSystemInterestedListByApi = async () => {
        try {
            const res = await api.getSystemInterestedList();
            return Promise.resolve(res);
        } catch (error: any) {
            const errMess =
                error?.message || "fetch system interested list failed";
            MessageAlert(errMess);
        }
    };

    return {
        _fetchAllInterestedListByApi,
        _setAddInterestedByApi,
        _fetchAllSystemInterestedListByApi,
    };
};
