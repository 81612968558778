import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/layout/step_done_icon.png'
import _imports_1 from '@/assets/icons/icon_input_clear_plain.svg'
import _imports_2 from '@/assets/images/layout/next_icon.png'
import _imports_3 from '@/assets/icons/icon_layout_eye.svg'
import _imports_4 from '@/assets/icons/icon_layout_password.svg'
import _imports_5 from '@/assets/images/layout/edit_password_success.png'
import _imports_6 from '@/assets/images/layout/edit_password_fail.png'


const _hoisted_1 = { class: "forget-password-message-cont" }
const _hoisted_2 = {
  key: 0,
  class: "step-bar-cont"
}
const _hoisted_3 = { class: "number-cont" }
const _hoisted_4 = {
  key: 0,
  class: "number current-step"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_0
}
const _hoisted_6 = {
  key: 3,
  src: _imports_0
}
const _hoisted_7 = { class: "text-cont" }
const _hoisted_8 = {
  key: 1,
  class: "email-address-input-cont"
}
const _hoisted_9 = { class: "verify-btn-cont" }
const _hoisted_10 = {
  key: 2,
  class: "verify-identity-cont"
}
const _hoisted_11 = {
  key: 3,
  class: "new-password-input-cont"
}
const _hoisted_12 = {
  key: 0,
  src: _imports_3
}
const _hoisted_13 = {
  key: 1,
  src: _imports_4
}
const _hoisted_14 = {
  key: 0,
  src: _imports_3
}
const _hoisted_15 = {
  key: 1,
  src: _imports_4
}
const _hoisted_16 = { class: "send-btn-cont" }
const _hoisted_17 = {
  key: 4,
  class: "edit-password-success"
}
const _hoisted_18 = { class: "back-to-login-btn-cont" }
const _hoisted_19 = {
  key: 5,
  class: "edit-password-fail"
}
const _hoisted_20 = { class: "return-btn-cont" }

import { NForm, NFormItem, NInput, NButton, NIcon } from "naive-ui";
import type {
    FormInst,
    FormItemProps,
    FormItemRule,
    FormRules,
} from "naive-ui";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { REQUEST_SCCUESS_CODE } from "@/constant";
import { LOGIN_TOKEN_KEY } from "@/constant";
import storage from "@/utils/storage";
import { AxiosResponse } from "axios";
import { MessageAlert } from "@/composables/useMessageAlert";
import { validateEmail, validatePassword } from "@/utils/util";
import { useRegisterAct } from "@/composables/useRegisterAct";

type FormItemThemeOverrides = NonNullable<FormItemProps["themeOverrides"]>;

export default /*@__PURE__*/_defineComponent({
  __name: 'ForgetPasswordMain',
  props: {
    currentStep: {
        type: Number,
        default: () => {
            return 1;
        },
    },
},
  emits: ["update:currentStep", "onLogin"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const formItemThemeOverrides: FormItemThemeOverrides = {
    feedbackPadding: "12px 0",
};
const store = useStore();
const { _clickVerifyEmailByApi, _isConfirmEmailByApi, _resetPasswordByApi } =
    useRegisterAct();
const cacheUserRole = ref<string>("USER");
const forgetPasswordStep = ref<number>(1);
const showForgotPassword = ref<boolean>(false);
const showForgotConfirmPassword = ref<boolean>(false);
const forgetPasswordEmailFormRef = ref<FormInst | null>(null);
const forgetPasswordEmail = ref<any>({
    email: null,
});
const isDisabledForgetPasswordEmail = computed(() => {
    return forgetPasswordEmail.value.email ? false : true;
});

const forgetPasswordEmailRules: FormRules = {
    email: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateEmail(value)) {
                    return new Error("Please enter a valid email address");
                }
                return true;
            },
        },
    ],
};

const showForgotEmailClearBtn = computed(() => {
    return forgetPasswordEmail.value.email ? true : false;
});

const forgetPasswordFormRef = ref<FormInst | null>(null);
const forgetPasswordModel = ref<any>({
    password: null,
    confirmPassword: null,
});
const isDisabledForgetPasswordModel = computed(() => {
    return forgetPasswordModel.value.password &&
        forgetPasswordModel.value.confirmPassword
        ? false
        : true;
});

const showForgotPasswordClearBtn = computed(() => {
    return forgetPasswordModel.value.password ? true : false;
});

const showForgotConfirmPasswordClearBtn = computed(() => {
    return forgetPasswordModel.value.confirmPassword ? true : false;
});

const forgetPasswordModelRules: FormRules = {
    password: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validatePassword(value)) {
                    return new Error("password must 6~25 letters and numbers");
                }
                return true;
            },
        },
    ],
    confirmPassword: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validatePassword(value)) {
                    return new Error("password must 6~25 letters and numbers");
                }
                return true;
            },
        },
    ],
};

const _handleClearForgotEmail = () => {
    forgetPasswordEmail.value.email = "";
};

const _handleClearForgotPassword = () => {
    forgetPasswordModel.value.password = "";
};

const _handleShowPassword = () => {
    showForgotPassword.value = !showForgotPassword.value;
};

const _handleShowConfirmPassword = () => {
    showForgotConfirmPassword.value = !showForgotConfirmPassword.value;
};

const _handleClearForgotConfirmPassword = () => {
    forgetPasswordModel.value.confirmPassword = "";
};

const updateCreateAccountStep = (step: number) => {
    emits("update:currentStep", step);
};

watch(
    () => props.currentStep,
    (newV) => {
        forgetPasswordStep.value = newV;
    },
    { immediate: true }
);

watch(
    () => forgetPasswordStep.value,
    (newV) => {
        updateCreateAccountStep(newV);
    },
    { immediate: true }
);

const _handleValidEmail = () => {
    if (isDisabledForgetPasswordEmail.value) {
        MessageAlert("Please input correct email");
        return;
    }
    //forgetPasswordStep.value = 2;
    forgetPasswordEmailFormRef.value?.validate(async (errors) => {
        if (!errors) {
            const params: any = {
                email: forgetPasswordEmail.value.email,
                userRole: cacheUserRole.value,
            };
            const res: any = await _clickVerifyEmailByApi(params);
            if (res?.code === REQUEST_SCCUESS_CODE) {
                //下一步
                // const successMess = res?.msg || "email resend success";
                // MessageAlert(successMess);
                forgetPasswordStep.value = 2;
            } else {
                const errMess = res?.msg || "email verify failed";
                MessageAlert(errMess);
            }
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please enter a valid email address";
            MessageAlert(errMess);
        }
    });
};

const _hanleResendEmail = () => {
    _handleValidEmail();
};

const _hanleCheckVerify = async () => {
    //forgetPasswordStep.value = 3;
    const params: any = {
        email: forgetPasswordEmail.value.email,
        userRole: cacheUserRole.value,
    };
    const res: any = await _isConfirmEmailByApi(params);
    if (res?.code === REQUEST_SCCUESS_CODE) {
        //下一步
        forgetPasswordStep.value = 3;
    } else {
        let errMess = res?.msg || "email confirm failed";
        MessageAlert(errMess);
    }
};

const handleResetPassword = () => {
    if (isDisabledForgetPasswordModel.value) {
        MessageAlert("Please enter password");
        return;
    }
    if (
        forgetPasswordModel.value.password !==
        forgetPasswordModel.value.confirmPassword
    ) {
        MessageAlert("confirmPassword must be consistent");
        return;
    }
    //forgetPasswordStep.value = 4;
    forgetPasswordFormRef.value?.validate(async (error) => {
        if (!error) {
            const params = {
                email: forgetPasswordEmail.value.email,
                newPassword: forgetPasswordModel.value.password,
                confirmPassword: forgetPasswordModel.value.confirmPassword,
            };
            const res: any = await _resetPasswordByApi(params);
            if (res?.code === REQUEST_SCCUESS_CODE) {
                const token = res.data.token;
                const accountType = res.data.userRole === "USER" ? 1 : 2;
                token && storage.set(LOGIN_TOKEN_KEY, token);
                store.dispatch("user/setAccountTypeAct", accountType);
                forgetPasswordStep.value = 4;
            } else {
                const errMess = res?.msg || "reset password failed";
                MessageAlert(errMess);
            }
        } else {
            MessageAlert("password must 6~25 letters and numbers");
        }
    });
};

const _handleShowLogin = () => {
    emits("onLogin");
};

const _initSlefPage = () => {
    const cacheUserRoleLocal = store.getters["user/getUserRole"];
    cacheUserRole.value = cacheUserRoleLocal;
};

onMounted(() => {
    nextTick(() => {
        _initSlefPage();
    });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
                forgetPasswordStep.value === 1 ||
                forgetPasswordStep.value === 2 ||
                forgetPasswordStep.value === 3
            )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (forgetPasswordStep.value === 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 1 "))
              : _createCommentVNode("", true),
            (forgetPasswordStep.value === 2 || forgetPasswordStep.value === 3)
              ? (_openBlock(), _createElementBlock("img", _hoisted_5))
              : _createCommentVNode("", true),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "line current-step" }, null, -1)),
            (forgetPasswordStep.value === 1 || forgetPasswordStep.value === 2)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["number", { 'current-step': forgetPasswordStep.value === 2 }])
                }, " 2 ", 2))
              : _createCommentVNode("", true),
            (forgetPasswordStep.value === 3)
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["line", { 'current-step': forgetPasswordStep.value === 3 }])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["number", { 'current-step': forgetPasswordStep.value === 3 }])
            }, " 3 ", 2)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "current-step" }, "Enter Your Email", -1)),
            _createElementVNode("div", {
              class: _normalizeClass({
                        'current-step':
                            forgetPasswordStep.value === 2 ||
                            forgetPasswordStep.value === 3,
                    })
            }, " Verify your identity ", 2),
            _createElementVNode("div", {
              class: _normalizeClass({ 'current-step': forgetPasswordStep.value === 3 })
            }, " Change your Password ", 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (forgetPasswordStep.value === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_unref(NForm), {
            ref_key: "forgetPasswordEmailFormRef",
            ref: forgetPasswordEmailFormRef,
            model: forgetPasswordEmail.value,
            rules: forgetPasswordEmailRules
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(NFormItem), {
                path: "email",
                label: "Email Address",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: forgetPasswordEmail.value.email,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((forgetPasswordEmail.value.email) = $event)),
                    placeholder: "Enter your Email Address",
                    size: "large",
                    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearForgotEmail
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createElementVNode("img", { src: _imports_1 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showForgotEmailClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_unref(NButton), {
              color: 
                        !isDisabledForgetPasswordEmail.value
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    ,
              "text-color": 
                        !isDisabledForgetPasswordEmail.value ? '#88EEFF' : '#ffffff'
                    ,
              disabled: isDisabledForgetPasswordEmail.value,
              "icon-placement": "right",
              onClick: _handleValidEmail
            }, {
              icon: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("img", {
                  style: {"margin-left":"12px","width":"26px","height":"14px"},
                  src: _imports_2
                }, null, -1)
              ])),
              default: _withCtx(() => [
                (isDisabledForgetPasswordEmail.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" Next ")
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" Verify ")
                    ], 64))
              ]),
              _: 1
            }, 8, ["color", "text-color", "disabled"])
          ]),
          _createElementVNode("div", { class: "log-in-tip" }, [
            _cache[11] || (_cache[11] = _createTextVNode(" Back to ")),
            _createElementVNode("span", { onClick: _handleShowLogin }, "Log in")
          ])
        ]))
      : _createCommentVNode("", true),
    (forgetPasswordStep.value === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "has-sended-message" }, [
            _createTextVNode(" We have sent you an email to verify that your email address is "),
            _createElementVNode("span", null, "marketing@charm-life.com")
          ], -1)),
          _createElementVNode("div", { class: "btn-group" }, [
            _createElementVNode("div", {
              class: "custom-btn resend-btn",
              onClick: _hanleResendEmail
            }, " Resend Email "),
            _createElementVNode("div", {
              class: "custom-btn check-btn",
              onClick: _hanleCheckVerify
            }, " Check Verify ")
          ])
        ]))
      : _createCommentVNode("", true),
    (forgetPasswordStep.value === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_unref(NForm), {
            ref_key: "forgetPasswordFormRef",
            ref: forgetPasswordFormRef,
            model: forgetPasswordModel.value,
            rules: forgetPasswordModelRules
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(NFormItem), {
                path: "password",
                label: "New Password",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: forgetPasswordModel.value.password,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((forgetPasswordModel.value.password) = $event)),
                    type: showForgotPassword.value ? 'text' : 'password',
                    placeholder: "Enter Your New Password",
                    size: "large",
                    onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "24",
                        onClick: _handleShowPassword
                      }, {
                        default: _withCtx(() => [
                          (showForgotPassword.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                            : (_openBlock(), _createElementBlock("img", _hoisted_13))
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearForgotPassword
                      }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createElementVNode("img", { src: _imports_1 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showForgotPasswordClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value", "type"])
                ]),
                _: 1
              }),
              _createVNode(_unref(NFormItem), {
                path: "confirmPassword",
                label: "Re-enter your New Password",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: forgetPasswordModel.value.confirmPassword,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((forgetPasswordModel.value.confirmPassword) = $event)),
                    type: showForgotConfirmPassword.value ? 'text' : 'password',
                    placeholder: "Confirm Your New Password",
                    size: "large",
                    onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "24",
                        onClick: _handleShowConfirmPassword
                      }, {
                        default: _withCtx(() => [
                          (showForgotConfirmPassword.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                            : (_openBlock(), _createElementBlock("img", _hoisted_15))
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearForgotConfirmPassword
                      }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createElementVNode("img", { src: _imports_1 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showForgotConfirmPasswordClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value", "type"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_unref(NButton), {
              color: 
                        !isDisabledForgetPasswordModel.value
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    ,
              "text-color": 
                        !isDisabledForgetPasswordModel.value ? '#88EEFF' : '#ffffff'
                    ,
              disabled: isDisabledForgetPasswordModel.value,
              "icon-placement": "right",
              onClick: handleResetPassword
            }, {
              icon: _withCtx(() => _cache[15] || (_cache[15] = [
                _createElementVNode("img", {
                  style: {"margin-left":"12px","width":"26px","height":"14px"},
                  src: _imports_2
                }, null, -1)
              ])),
              default: _withCtx(() => [
                (isDisabledForgetPasswordModel.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" Next ")
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" Send ")
                    ], 64))
              ]),
              _: 1
            }, 8, ["color", "text-color", "disabled"])
          ])
        ]))
      : _createCommentVNode("", true),
    (forgetPasswordStep.value === 4)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "img-cont" }, [
            _createElementVNode("img", { src: _imports_5 })
          ], -1)),
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "title" }, "Password changed succesfully", -1)),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "describe" }, " Your password has been changed successfully. we will let you know if there are more problems with your account ", -1)),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_unref(NButton), {
              color: "#000000",
              "text-color": "#88EEFF",
              "icon-placement": "right",
              onClick: _handleShowLogin
            }, {
              icon: _withCtx(() => _cache[16] || (_cache[16] = [
                _createElementVNode("img", {
                  style: {"margin-left":"12px","width":"26px","height":"14px"},
                  src: _imports_2
                }, null, -1)
              ])),
              default: _withCtx(() => [
                _cache[17] || (_cache[17] = _createTextVNode(" Back to Login "))
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (forgetPasswordStep.value === 5)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "img-cont" }, [
            _createElementVNode("img", { src: _imports_6 })
          ], -1)),
          _cache[23] || (_cache[23] = _createElementVNode("div", { class: "title" }, "Something went wrong", -1)),
          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "describe" }, "Please give it another whirl", -1)),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_unref(NButton), {
              color: "#000000",
              "text-color": "#88EEFF",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (forgetPasswordStep.value = 3))
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode("Return")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})