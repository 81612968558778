/**
 * store post
 */

import { PostState } from "../type";

export default {
    namespaced: true,
    state: (): PostState => ({
        curTab: undefined,
        postList: [],
    }),
    mutations: {
        setCurTab(state: PostState, value: string): void {
            state.curTab = value;
        },
    },
    actions: {
        setCurTabAct({ commit }: any, value: string): void {
            commit("setCurTab", value);
        },
    },
    getters: {
        getCurTab(state: PostState) {
            return state.curTab;
        },
    },
};
