/**
 * MockList
 */

export const filterOptionsMockList = [
    {
        value: "Acne Treatments",
        label: "Acne Treatments",
        disabled: false,
        children: [
            {
                value: "AviClear",
                label: "AviClear",
                disabled: false,
            },
            {
                value: "NeoGen Plasma",
                label: "NeoGen Plasma",
                disabled: false,
            },
            {
                value: "Fractora RF",
                label: "Fractora RF",
                disabled: false,
            },
            {
                value: "BroadBand Light (BBL)",
                label: "BroadBand Light (BBL)",
                disabled: false,
            },
            {
                value: "Accutane",
                label: "Accutane",
                disabled: false,
            },
            {
                value: "Excel V",
                label: "Excel V",
                disabled: false,
            },
            {
                value: "Neo Laser",
                label: "Neo Laser",
                disabled: false,
            },
            {
                value: "Photodynamic Therapy",
                label: "Photodynamic Therapy",
                disabled: false,
            },
        ],
    },
    {
        value: "Body Contouring",
        label: "Body Contouring",
        disabled: false,
        children: [
            {
                value: "Lipedema Surgery",
                label: "Lipedema Surgery",
                disabled: false,
            },
            {
                value: "Hip Augmentation",
                label: "Hip Augmentation",
                disabled: false,
            },
            {
                value: "AirSculpt",
                label: "AirSculpt",
                disabled: false,
                children: [
                    {
                        value: "Cynthia Test 1",
                        label: "Cynthia",
                        disabled: false,
                    },
                    {
                        value: "Margaret Test 2",
                        label: "Margaret",
                        disabled: false,
                    },
                ],
            },
            {
                value: "Pec Implants",
                label: "Pec Implants",
                disabled: false,
            },
            {
                value: "Arm Lift",
                label: "Arm Lift",
                disabled: false,
            },
        ],
    },
    {
        value: "Breast Reconstruction",
        label: "Breast Reconstruction",
        disabled: false,
    },
    {
        value: "Breast Surgery",
        label: "Breast Surgery",
        disabled: false,
    },
    {
        value: "Hip & Butt Enhancement",
        label: "Hip & Butt Enhancement",
        disabled: false,
    },
    {
        value: "Cheek, Chin And Jawline Enhancement",
        label: "Cheek, Chin And Jawline Enhancement",
        disabled: false,
    },
    {
        value: "Cosmetic Dentistry",
        label: "Cosmetic Dentistry",
        disabled: false,
    },
    {
        value: "Medical Dermatology",
        label: "Medical Dermatology",
        disabled: false,
    },
    {
        value: "Eyebrow And Lash Enhancement",
        label: "Eyebrow And Lash Enhancement",
        disabled: false,
    },
];

export const postMockList = [
    {
        imgSrc: require("@/assets/images/post/post_image1.png"),
        coverImg: require("@/assets/images/post/post_image1.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image2.png"),
        coverImg: require("@/assets/images/post/post_image2.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image3.png"),
        coverImg: require("@/assets/images/post/post_image3.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image4.png"),
        coverImg: require("@/assets/images/post/post_image4.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image5.png"),
        coverImg: require("@/assets/images/post/post_image5.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image6.png"),
        coverImg: require("@/assets/images/post/post_image6.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image7.png"),
        coverImg: require("@/assets/images/post/post_image7.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image8.png"),
        coverImg: require("@/assets/images/post/post_image8.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image9.png"),
        coverImg: require("@/assets/images/post/post_image9.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image10.png"),
        coverImg: require("@/assets/images/post/post_image10.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image11.png"),
        coverImg: require("@/assets/images/post/post_image11.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image12.png"),
        coverImg: require("@/assets/images/post/post_image12.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image13.png"),
        coverImg: require("@/assets/images/post/post_image13.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image14.png"),
        coverImg: require("@/assets/images/post/post_image14.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image15.png"),
        coverImg: require("@/assets/images/post/post_image15.png"),
        message: "Two weeks after surgery, as a first time member...",
        likeCount: 33,
        authorName: "Anna",
        nickname: "Anna",
        authorHeaderImg: require("@/assets/images/post/author_header_img.png"),
        avatar: require("@/assets/images/post/author_header_img.png"),
    },
];

export const likeDetailMockList = [
    {
        authorHeaderImg: require("@/assets/images/post/like_img1.png"),
        authorName1: "hang_channnn",
        authorName2: "hangchan",
        isFollowing: false,
    },
    {
        authorHeaderImg: require("@/assets/images/post/like_img2.png"),
        authorName1: "milkcat0319",
        authorName2: "WeiChung Chen",
        isFollowing: true,
    },
    {
        authorHeaderImg: require("@/assets/images/post/like_img3.png"),
        authorName1: "klumpigerschleim",
        authorName2: "yiyangzhou",
        isFollowing: false,
    },
    {
        authorHeaderImg: require("@/assets/images/post/like_img4.png"),
        authorName1: "kaity·weng",
        authorName2: "kaity Weng",
        isFollowing: false,
    },
    {
        authorHeaderImg: require("@/assets/images/post/like_img5.png"),
        authorName1: "shaohsuan1992",
        authorName2: "ShaoHsuan Huang",
        isFollowing: false,
    },
];

export const detailImagesMockList = [
    {
        imgSrc: require("@/assets/images/post/post_detail_image.png"),
    },
    {
        imgSrc: require("@/assets/images/post/post_image11.png"),
    },
];

export const detailCommentsMockList = [
    {
        authorHeaderImg: require("@/assets/images/post/like_img2.png"),
        authorName: "Tom",
        comment: "Thanks for sharing",
        time: "15:28 Today",
        likeNumbers: 33,
        toComments: 0,
        currentTime: new Date().getTime(),
    },
];

export const procedureMockList = [
    {
        imgSrc: require("@/assets/images/procedure/types/botox_injections.svg"),
        name: "Botox Injection",
        categoryName: "botox_injections",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/breast_augmentation.svg"),
        name: "Breast Augmentation",
        categoryName: "breast_augmentation",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/chemical_peels.svg"),
        name: "Chemical Peels",
        categoryName: "chemical_peels",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/chin_implants.svg"),
        name: "Chin Implants",
        categoryName: "chin_implants",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/fox_eyes.svg"),
        name: "Fox Eyes",
        categoryName: "fox_eyes",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/facelift.svg"),
        name: "Face lift",
        categoryName: "facelift",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/laser_hair_removal.svg"),
        name: "Laser Hair Removal",
        categoryName: "laser_hair_removal",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/lip_augmentation.svg"),
        name: "Lip augmentation",
        categoryName: "lip_augmentation",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/otoplasty.svg"),
        name: "Otoplasty",
        categoryName: "otoplasty",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/neck_contouring.svg"),
        name: "Neck Contouring",
        categoryName: "neck_contouring",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/teeth_whitening.svg"),
        name: "Teeth Whitening",
        categoryName: "teeth_whitening",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/tummy_tuck.svg"),
        name: "Tummy Tuck",
        categoryName: "tummy_tuck",
    },
];

export const defaultTopicMockArr = [
    {
        tagName: "Facelift",
        viewCount: "35.81 Million Views",
    },
    {
        tagName: "Facial Rejuvination",
        viewCount: "25.60 Million Views",
    },
    {
        tagName: "Fat Transfer",
        viewCount: "18.62 Million Views",
    },
    {
        tagName: "Fractional Laser Resurfacing",
        viewCount: "16.22 Million Views",
    },
    {
        tagName: "Fillers",
        viewCount: "11.48 Million Views",
    },
];

export const defaultUserMockArr = [
    {
        name: "kaity Weng",
        headerImg: require("@/assets/images/post/like_img1.png"),
    },
    {
        name: "WeiChung Chen",
        headerImg: require("@/assets/images/post/like_img2.png"),
    },
    {
        name: "yiyangzhou",
        headerImg: require("@/assets/images/post/like_img3.png"),
    },
    {
        name: "ShaoHsuan Huang",
        headerImg: require("@/assets/images/post/like_img4.png"),
    },
    {
        name: "hangchan",
        headerImg: require("@/assets/images/post/like_img5.png"),
    },
];

export const categoryMockOptions = [
    {
        label: "Facial",
        value: "Facial",
    },
    {
        label: "Breast",
        value: "Breast",
    },
];

export const costMockOptions = [
    {
        label: "5000",
        value: "5000",
    },
    {
        label: "10000",
        value: "10000",
    },
    {
        label: "20000",
        value: "20000",
    },
];

export const rateMockOptions = [
    {
        label: "3 star",
        value: "3 star",
    },
    {
        label: "4 star",
        value: "4 star",
    },
    {
        label: "5 star",
        value: "5 star",
    },
];

export const suggestedDoctorsMockList = [
    {
        id: 1,
        nickname: "Dr. Smith",
        address: "123 Medicine St",
        name: "John Smith",
        mechName: "Health Hospital",
    },
    {
        id: 2,
        nickname: "Dr. Johnson",
        address: "456 Wellness Ave",
        name: "Sarah Johnson",
        mechName: "Wellness Clinic",
    },
    {
        id: 3,
        nickname: "Dr. Johnson",
        address: "456 Wellness Ave",
        name: "Sarah Johnson",
        mechName: "Wellness Clinic",
    },
];

export const ProcedureTransMockList = [
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures1_new.png"),
        name: "Botox Injection",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures2_new.png"),
        name: "Breast Augmentation",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures3_new.png"),
        name: "Chemical Peels",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures4_new.png"),
        name: "Chin Implants",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures5_new.png"),
        name: "Fox Eyes",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures6_new.png"),
        name: "Facelift",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures7_new.png"),
        name: "Laser Hair Removal",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures8_new.png"),
        name: "Botox Injection",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures9_new.png"),
        name: "Otoplasty",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures10_new.png"),
        name: "Neck Contouring",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures11_new.png"),
        name: "Teeth Whitening",
    },
    {
        imgSrc: require("@/assets/images/home/cosmetic_procedures12_new.png"),
        name: "Tummy Tuck",
    },
];

export const InstrumentsFaqMockList = [
    {
        title: "What is the function of the instrument?",
        content:
            "The instrument measures the frequency of the sound waves and provides accurate data about the pitch of the note being played.",
    },
    {
        title: "How to maintain the instrument?",
        content:
            "Regular cleaning and proper storage are essential. Avoid exposing the instrument to extreme temperatures or humidity.",
    },
    {
        title: "Where can I buy replacement parts?",
        content:
            "Replacement parts can be purchased from our official website or from authorized dealers.",
    },
    {
        title: "What is the warranty period?",
        content:
            "The instrument comes with a one-year warranty, covering manufacturing defects.",
    },
];

export const AreasMockList = [
    {
        label: "New York",
        value: "New York",
    },
    {
        label: "Los Angeles",
        value: "Los Angeles",
    },
    {
        label: "Chicago",
        value: "Chicago",
    },
    {
        label: "Houston",
        value: "Houston",
    },
    {
        label: "Montgomery",
        value: "Montgomery",
    },
];

export const DoctorJointMenuStepMockList = [
    {
        label: "Basic Information",
        name: "Basic Information",
        step: 1,
    },
    {
        label: "Professional Qualifications",
        name: "Professional Qualifications",
        step: 2,
    },
    {
        label: "Education Background",
        name: "Professional Qualifications",
        step: 3,
    },
    {
        label: "Hospital Information",
        name: "Professional Qualifications",
        step: 4,
    },
    {
        label: "Work Experience",
        name: "Professional Qualifications",
        step: 5,
    },
    {
        label: "Cases and Achievements",
        name: "Professional Qualifications",
        step: 6,
    },
];

export const CouponMockList = [
    {
        id: 1,
        imgSrc: require("@/assets/images/procedure/types/botox_injections.svg"),
        name: "Botox Injection",
        time: "12 hours left",
        beforePrice: "99",
        afterPrice: "69",
    },
    {
        id: 2,
        imgSrc: require("@/assets/images/procedure/types/breast_augmentation.svg"),
        name: "Breast Augmentation",
        time: "12 hours left",
        beforePrice: "99",
        afterPrice: "69",
    },
    {
        id: 3,
        imgSrc: require("@/assets/images/procedure/types/chemical_peels.svg"),
        name: "Chemical Peels",
        time: "12 hours left",
        beforePrice: "99",
        afterPrice: "69",
    },
];
