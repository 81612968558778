import {
    createRouter,
    createWebHashHistory,
    createWebHistory,
    RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/home/homeIndex.vue";
const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
        meta: {
            level: 1,
        },
    },
    {
        path: "/about",
        name: "About",
        component: () => import("../views/AboutView.vue"),
        meta: {
            level: 1,
        },
    },
    {
        path: "/doctor",
        name: "Doctor",
        component: () => import("../views/doctor/doctorIndex.vue"),
        meta: {
            level: 1,
        },
    },
    {
        path: "/doctor/detail",
        name: "DoctorDetail",
        component: () => import("../views/doctor/DoctorDetail.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/doctor/profile",
        name: "DoctorProfile",
        component: () => import("../views/doctor/MemberProfile.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/doctor/appoint",
        name: "DoctorAppoint",
        component: () => import("../views/doctor/DoctorAppoint.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/doctor/vouchers",
        name: "VoucherDetail",
        component: () => import("../views/user/VoucherDetail.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/post",
        name: "Post",
        component: () => import("../views/post/postIndex.vue"),
        meta: {
            level: 1,
            keepAlive: true,
        },
        children: [
            {
                path: ":id",
                name: "PostDetail",
                component: () => import("../views/post/postDetail.vue"),
                meta: {
                    level: 2,
                    keepAlive: true,
                },
            },
        ],
    },
    {
        path: "/procedure",
        name: "Procedure",
        component: () => import("../views/procedure/procedureIndex.vue"),
        meta: {
            level: 1,
        },
    },
    {
        path: "/procedure/procedureDetail",
        name: "ProcedureDetail",
        component: () => import("../views/procedure/procedureDetailLocal.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/instrument",
        name: "Instrument",
        component: () => import("../views/instrument/instrumentIndex.vue"),
        meta: {
            level: 1,
        },
    },
    {
        path: "/instrument/instrumentDetail",
        name: "InstrumentDetail",
        component: () => import("../views/instrument/instrumentDetailLocal.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/user/detail",
        name: "UserDetail",
        component: () => import("../views/user/UserDetail.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/user/profile",
        name: "UserProfile",
        component: () => import("../views/doctor/MemberProfile.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/user/appoint",
        name: "UserAppoint",
        component: () => import("../views/doctor/DoctorAppoint.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/user/doctorjoin",
        name: "DoctorJoin",
        component: () => import("../views/doctor/DoctorJoin.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/contactUs",
        name: "ContactUs",
        component: () => import("../views/other/ContactUs.vue"),
        meta: {
            level: 1,
        },
    },
    {
        path: "/contactUs/result",
        name: "ContactUsResult",
        component: () => import("../views/other/ContactUsResult.vue"),
        meta: {
            level: 2,
        },
    },
    {
        path: "/valideEmail",
        name: "ValideEmailResult",
        component: () => import("../views/other/ValideEmailResult.vue"),
        meta: {
            level: 1,
        },
    },
];

//console.log("process.env=====", process.env);
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), //createWebHashHistory
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
    next();
});

export default router;
