import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '@/assets/icons/icon_input_clear_plain.svg'
import _imports_1 from '@/assets/icons/icon_layout_eye.svg'
import _imports_2 from '@/assets/icons/icon_layout_password.svg'
import _imports_3 from '@/assets/icons/ic_google_search.svg'


const _hoisted_1 = { class: "login-main-container" }
const _hoisted_2 = {
  key: 0,
  class: "select-user-identify"
}
const _hoisted_3 = {
  key: 1,
  class: "login-message-cont"
}
const _hoisted_4 = {
  key: 0,
  src: _imports_1
}
const _hoisted_5 = {
  key: 1,
  src: _imports_2
}
const _hoisted_6 = { class: "remember-and-forget-password-cont" }
const _hoisted_7 = { class: "login-btn-cont" }
const _hoisted_8 = { class: "other-login-mode" }

import { ref, computed, watch, nextTick } from "vue";
import { useStore } from "vuex";
import {
    NForm,
    NFormItem,
    NInput,
    NCheckbox,
    NButton,
    NIcon,
    FormInst,
    FormRules,
    FormItemRule,
} from "naive-ui";
import { REQUEST_SCCUESS_CODE, USER_INDENTIFY_KEY } from "@/constant";
import { UserRole, SimpleLoginType } from "@/constant";
import storage from "@/utils/storage";
import ChoseUserIdentify from "./ChoseUserIdentify.vue";
import { MessageAlert } from "@/composables/useMessageAlert";
import { validateEmail, validateLoginPassword } from "@/utils/util";
import { loginWithSocial } from "@/utils/third-login";
import { jwtDecode } from "jwt-decode";
import {
    googleLogout,
    googleTokenLogin,
    decodeCredential,
} from "vue3-google-login";
import type { CallbackTypes } from "vue3-google-login";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginMain',
  props: {
    currentStep: {
        type: Number,
        default: () => {
            return 1;
        },
    },
},
  emits: [
    "update:currentStep",
    "onLoginIn",
    "onRegister",
    "onForgotPassword",
    "onClose",
],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;
const store = useStore();
const showClearPassword = ref<boolean>(false);
const loginMessageFormRef = ref<FormInst | null>(null);
const curLoginStep = ref<number>(1);
const loginMessageModel = ref<any>({
    email: null,
    password: null,
});
const loginModelRules: FormRules = {
    email: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateEmail(value)) {
                    return new Error("Please enter a valid email address");
                }
                return true;
            },
        },
    ],
    password: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value, validateLoginPassword(value));
                if (!value) {
                    return new Error("Please input");
                } else if (!validateLoginPassword(value)) {
                    return new Error("password must 6~25 letters and numbers");
                }
                return true;
            },
        },
    ],
};

const isDisabledLoginBtn = computed(() => {
    return loginMessageModel.value.email && loginMessageModel.value.password
        ? false
        : true;
});

const showLoginEmailClearBtn = computed(() => {
    return loginMessageModel.value.email ? true : false;
});

const showLoginPasswordClearBtn = computed(() => {
    return loginMessageModel.value.password ? true : false;
});

const isLogined = computed(() => {
    return store.getters["user/getLoginStatus"];
});

const updateLoginStep = (step: number) => {
    emits("update:currentStep", step);
};

watch(
    () => props.currentStep,
    (newV) => {
        curLoginStep.value = newV;
    },
    { immediate: true }
);

watch(
    () => curLoginStep.value,
    (newV) => {
        updateLoginStep(newV);
    },
    { immediate: true }
);

const _handleClearLoginEmail = () => {
    loginMessageModel.value.email = "";
};

const _handleClearLoginPassword = () => {
    loginMessageModel.value.password = "";
};

// 登陆输入密码监听
const handlePasswordInput = () => {
    console.log("密码输入变化");
};

const _handleRegister = (type?: string) => {
    emits("onRegister", type);
};

const _handleForgotPassword = () => {
    emits("onForgotPassword");
};

const _onGoogleLoginCallbackUser: CallbackTypes.CredentialCallback = async (
    response: any
) => {
    // This callback will be triggered when the user selects or login to his Google account from the popup
    console.log("Credential JWT string", response);
    const userData = decodeCredential(response.credential);
    console.log("Handle Google the userData", userData);
    const params = {
        email: userData?.email,
        provider: "google",
    };
    await store.dispatch("user/userLoginIn", params);
};

const _goToRegister = (type?: string) => {
    _handleRegister(type);
};

const _onGoogleLoginCallbackToken: CallbackTypes.TokenResponseCallback = async (
    response: any
) => {
    //console.log("Access token", response);
    const accessToken = response?.access_token;
    // console.log("Handle Google the accessToken", accessToken);
    const userType = storage.get(USER_INDENTIFY_KEY) || 1;
    const params = {
        googleAccessToken: accessToken,
        provider: "google",
        userRole: Number(userType) === 1 ? UserRole.User : UserRole.Doctor,
    };
    const res = await store.dispatch("user/userLoginIn", params);
    //console.log("Access res", res);
    if (res.code === REQUEST_SCCUESS_CODE) {
        _goToRegister("thirdlogin");
    }
};

const onGoogleLoginError = (error: any) => {
    MessageAlert(
        error?.error?.message ||
            "Sorry, Google login failed, please login use by email accocunt"
    );
};

const _loginUserByApi = async () => {
    const userType = storage.get(USER_INDENTIFY_KEY) || 1;
    const params = {
        ...loginMessageModel.value,
        provider: "email",
        userRole: Number(userType) === 1 ? UserRole.User : UserRole.Doctor,
    };
    try {
        await store.dispatch("user/userLoginIn", params);
        await store.dispatch("user/fetchUserInfo");
        if (isLogined.value) {
            _handleCloseLoginModel();
        }
    } catch (error: any) {
        const errMess = error?.message || "login failed";
        MessageAlert(errMess);
    }
};

const _handleCloseLoginModel = () => {
    nextTick(() => {
        emits("onClose");
    });
};

// 登陆
const loginCharm = () => {
    loginMessageFormRef.value?.validate((errors) => {
        if (!errors) {
            _loginUserByApi();
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please enter a valid email address";
            MessageAlert(errMess);
        }
    });
};

const _handleShowPassword = () => {
    showClearPassword.value = !showClearPassword.value;
};

const _handleSimpleLogin = async (type: SimpleLoginType) => {
    try {
        //const res = await loginWithSocial(type);
        if (type === SimpleLoginType.Google) {
            googleTokenLogin().then((response) => {
                console.log("Handle the response", response);
            });
            // const resData: any = jwtDecode(res.credential);
            // console.log("Google====>", resData);
            // const params = {
            //     googleAccessToken: resData.googleAccessToken,
            //     provider: "google",
            // };
            // await store.dispatch("user/userLoginSocial", params);
        } else if (type === SimpleLoginType.Apple) {
            // const resData: any = jwtDecode(res.authorization.id_token);
            // console.log("Apple====>", resData);
            // const params = {
            //     appleIdToken: resData.appleIdToken,
            //     provider: "google",
            // };
            // await store.dispatch("user/userLoginSocial", params);
        } else if (type === SimpleLoginType.Facebook) {
            (window as any).FB.api("/me?fields=email,name", (resp: any) => {
                const resData = resp;
                console.log("Facebook====>", resData);
                // const params = {
                //     appleIdToken: resData.appleIdToken,
                //     provider: "google",
                // };
                // await store.dispatch("user/userLoginSocial", params);
            });
        } else {
            console.error("No type for login, please check");
        }
    } catch (error) {
        console.error(`Login error with ${type}:`, error);
    }
};

const _handleLoginSelectIdentifyNext = (type: string) => {
    //缓存type身份
    store.dispatch("user/setAccountTypeAct", type);

    //进入登录页面
    curLoginStep.value = 2;
};

return (_ctx: any,_cache: any) => {
  const _component_GoogleLogin = _resolveComponent("GoogleLogin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (curLoginStep.value === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(ChoseUserIdentify, { onOnNext: _handleLoginSelectIdentifyNext })
        ]))
      : _createCommentVNode("", true),
    (curLoginStep.value === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_unref(NForm), {
            ref_key: "loginMessageFormRef",
            ref: loginMessageFormRef,
            model: loginMessageModel.value,
            rules: loginModelRules
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(NFormItem), {
                path: "email",
                label: "Email Address"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: loginMessageModel.value.email,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((loginMessageModel.value.email) = $event)),
                    placeholder: "Enter your Email Address",
                    size: "large",
                    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearLoginEmail
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createElementVNode("img", { src: _imports_0 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showLoginEmailClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(NFormItem), {
                path: "password",
                label: "Password"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: loginMessageModel.value.password,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((loginMessageModel.value.password) = $event)),
                    type: showClearPassword.value ? 'text' : 'password',
                    placeholder: "Enter Your Password",
                    size: "large",
                    onInput: handlePasswordInput,
                    onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "24",
                        onClick: _handleShowPassword
                      }, {
                        default: _withCtx(() => [
                          (showClearPassword.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                            : (_openBlock(), _createElementBlock("img", _hoisted_5))
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearLoginPassword
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createElementVNode("img", { src: _imports_0 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showLoginPasswordClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value", "type"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(NCheckbox), { value: "rememberAccount" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Remember me")
              ])),
              _: 1
            }),
            _createElementVNode("div", { onClick: _handleForgotPassword }, "Forgot your password?")
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(NButton), {
              color: 
                        !isDisabledLoginBtn.value ? '#000000' : 'rgba(0, 0, 0, 0.2)'
                    ,
              "text-color": !isDisabledLoginBtn.value ? '#88EEFF' : '#ffffff',
              disabled: isDisabledLoginBtn.value,
              onClick: loginCharm
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Log in ")
              ])),
              _: 1
            }, 8, ["color", "text-color", "disabled"])
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "line-or-line" }, [
            _createElementVNode("div", { class: "line" }),
            _createElementVNode("div", { class: "text" }, "OR"),
            _createElementVNode("div", { class: "line" })
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_GoogleLogin, {
              "popup-type": "TOKEN",
              buttonConfig: {
                        type: 'icon',
                        size: 'large',
                        shape: 'circle',
                    },
              callback: _onGoogleLoginCallbackToken,
              error: onGoogleLoginError
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("div", { class: "other-login-sec login-btn" }, [
                  _createElementVNode("img", { src: _imports_3 }),
                  _createElementVNode("span", null, "Sign in with Google")
                ], -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", { class: "create-account-tip" }, [
            _cache[9] || (_cache[9] = _createTextVNode(" Don’t have an account? ")),
            _createElementVNode("span", { onClick: _handleRegister }, "Create one!")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})