import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/layout/app_logo.png'
import _imports_1 from '@/assets/images/layout/user_login.png'


const _hoisted_1 = {
  key: 0,
  class: "menu-tab-btn"
}
const _hoisted_2 = { class: "menu-list-container" }
const _hoisted_3 = { class: "menu-list-wrap" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 2,
  class: "user-login"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "login-sec-container login-done-container"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  src: _imports_1
}
const _hoisted_10 = { class: "user-name" }
const _hoisted_11 = { class: "login-done-list" }
const _hoisted_12 = { class: "login-done-list-wrap" }
const _hoisted_13 = { class: "list-item-control ellipsis" }
const _hoisted_14 = { class: "ellipsis" }

import { NTabs, NTab, NModal, NPopover, NIcon, NMenu } from "naive-ui";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { Menu } from "@vicons/ionicons5";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import LoginAndRegister from "./LoginAndRegister.vue";
import DoctorAuthentication from "./DoctorAuthentication.vue";
import PromptModal from "@/components/PromptModal.vue";
import { useCalCScreenMinWidth } from "@/composables/useCalcScreenMinWidth";
import { MenuTabsList } from "@/constant";


export default /*@__PURE__*/_defineComponent({
  __name: 'TitleBar',
  props: {
    isHome: {
        type: Boolean,
        default: () => {
            return false;
        },
    },
},
  setup(__props) {

const props = __props;

const router = useRouter();
const { screenWinWidth } = useCalCScreenMinWidth();
const store = useStore();

const tabsValue = ref<string>("Home");
const isSrollTopDis = ref<boolean>(false);
const showMenuPopover = ref<boolean>(false);
const showPopover = ref<boolean>(false);
const isShowLoginAndRegisterModal = ref<boolean>(false);
const isShowDoctorAuthenticationModal = ref<boolean>(false);
const showConfirmLoginOutModal = ref<boolean>(false);
const isLogined = computed(() => {
    return store.getters["user/getLoginStatus"];
});
const showLoginModal = computed(() => {
    return store.getters["user/getShowLoginModal"];
});
const userInfo = computed(() => {
    return store.getters["user/getUserInfo"];
});
const rowIndex = computed(() => {
    return screenWinWidth.value <= 744
        ? 2
        : screenWinWidth.value <= 1024
        ? 3
        : 4;
});

const logoContOpacityStyle = ref<any>({
    display: "flex",
    opacity: 1,
});

const logoContFixedTopOpacityStyle = ref<any>({
    display: "none",
});

const isTitleBarFixed = computed(() => {
    return isSrollTopDis.value || !props.isHome;
});

watch(
    () => router.currentRoute.value,
    (to) => {
        const curMetaLevel = to.meta?.level;
        if (to.name === "Home" && curMetaLevel === 1) {
            tabsValue.value = "Home";
        } else if (
            (to.path.includes("/doctor") && curMetaLevel === 1) ||
            (to.path.includes("/doctor/") && curMetaLevel === 2)
        ) {
            tabsValue.value = "Doctors";
        } else if (
            (to.path.includes("/post") && curMetaLevel === 1) ||
            (to.path.includes("/post/") && curMetaLevel === 2)
        ) {
            tabsValue.value = "Posts";
        } else if (
            (to.path.includes("/procedure") && curMetaLevel === 1) ||
            (to.path.includes("/procedure/") && curMetaLevel === 2)
        ) {
            tabsValue.value = "Procedures";
        } else if (
            (to.path.includes("/instrument") && curMetaLevel === 1) ||
            (to.path.includes("/instrument/") && curMetaLevel === 2)
        ) {
            tabsValue.value = "Instruments";
        } else {
            tabsValue.value = "";
        }
    },
    { immediate: true }
);

watch(
    () => showLoginModal.value,
    (n: boolean) => {
        isShowLoginAndRegisterModal.value = n;
    }
);

// 切换tab
const _handleChangeTabPage = (value: string | number) => {
    // console.log(value);
    switch (value) {
        case "Home":
            router.push({ path: "/" });
            tabsValue.value = "Home";
            break;
        case "Doctors":
            router.push({ path: "/doctor" });
            tabsValue.value = "Doctors";
            break;
        case "Posts":
            router.push({ path: "/post" });
            tabsValue.value = "Posts";
            break;
        case "Procedures":
            router.push({ path: "/procedure" });
            tabsValue.value = "Procedures";
            break;
        case "Instruments":
            router.push({ path: "/instrument" });
            tabsValue.value = "Instruments";
            break;
    }
    nextTick(() => {
        _handleCloseMenuPopover();
    });
    //console.log(tabsValue);
};

// 展示登陆注册弹框
const showLoginAndRegisterModal = () => {
    isShowLoginAndRegisterModal.value = true;
};

// 关闭登陆注册弹框
const closeLoginAndRegisterModal = () => {
    isShowLoginAndRegisterModal.value = false;
};

// 关闭医生认证资料填写弹框
const closeDoctorAuthenticationModal = () => {
    isShowDoctorAuthenticationModal.value = false;
};

const _handleBackHome = () => {
    if (router.currentRoute.value.name === "Home") {
        return;
    }
    router.push({ path: "/" });
};

const _handleCloseMenuPopover = () => {
    showMenuPopover.value = false;
};

const _handleShowMenuPopover = () => {
    showMenuPopover.value = true;
};

const _handleCloseUserPopover = () => {
    showPopover.value = false;
};

const _handleShowUserPopover = () => {
    showPopover.value = true;
};

const _handleShowConfirmLoginOutModal = () => {
    showConfirmLoginOutModal.value = !showConfirmLoginOutModal.value;
};

const _handleLoginOut = () => {
    _handleShowConfirmLoginOutModal();
    nextTick(() => {
        _handleCloseUserPopover();
    });
};

const _onConfirmLoginOut = () => {
    store.dispatch("user/userLoginOut");
    nextTick(() => {
        router.push({ path: "/" });
    });
};

const _goToMyProfilePage = () => {
    nextTick(() => {
        router.push({
            path: "/user/detail",
            query: { memberId: userInfo.value?.userId },
        });
    });
};

const _handleGoToMyProfile = () => {
    _goToMyProfilePage();
    nextTick(() => {
        _handleCloseUserPopover();
    });
};

onMounted(() => {
    window.addEventListener("scroll", () => {
        const scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        // console.log("scrollTop===", scrollTop);

        logoContOpacityStyle.value.opacity =
            1 - scrollTop / 145 < 0 ? 0 : 1 - scrollTop / 145;

        if (logoContOpacityStyle.value.opacity === 0) {
            logoContOpacityStyle.value.display = "none";
        } else {
            logoContOpacityStyle.value.display = "flex";
        }

        if (scrollTop >= 145) {
            isSrollTopDis.value = true;
            logoContFixedTopOpacityStyle.value.display = "flex";
        } else {
            isSrollTopDis.value = false;
            logoContFixedTopOpacityStyle.value.display = "none";
        }
    });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["title-bar", { 'title-bar-fixed-top': isTitleBarFixed.value }])
  }, [
    (isTitleBarFixed.value && rowIndex.value === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(NPopover), {
            show: showMenuPopover.value,
            placement: "bottom-start",
            width: 140,
            "show-arrow": false,
            onClickoutside: _handleCloseMenuPopover,
            to: ".title-bar"
          }, {
            trigger: _withCtx(() => [
              _createVNode(_unref(NIcon), {
                size: 32,
                onClick: _handleShowMenuPopover
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Menu))
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(MenuTabsList), (item, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: idx,
                      class: "list-item-control",
                      onClick: ($event: any) => (_handleChangeTabPage(item.name))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(item.title), 1)
                    ], 8, _hoisted_4))
                  }), 128))
                ])
              ])
            ]),
            _: 1
          }, 8, ["show"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({
                'logo-cont animation-in': !isTitleBarFixed.value,
                'logo-cont-fixed-top animation-out': isTitleBarFixed.value,
            }),
      onClick: _handleBackHome
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("img", { src: _imports_0 }, null, -1),
      _createElementVNode("div", null, "Charm", -1)
    ]), 2),
    (!(isTitleBarFixed.value && rowIndex.value === 2))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["tab-menu", { 'tab-fixed-top': isTitleBarFixed.value }])
        }, [
          _createVNode(_unref(NTabs), {
            type: "bar",
            "default-value": "charmIndex",
            value: tabsValue.value
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(MenuTabsList), (item, idx) => {
                return (_openBlock(), _createBlock(_unref(NTab), {
                  key: idx,
                  name: item.name,
                  onClick: ($event: any) => (_handleChangeTabPage(item.name))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["name", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"])
        ], 2))
      : _createCommentVNode("", true),
    (isTitleBarFixed.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (!isLogined.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "login-sec-container login-undo-container",
                onClick: showLoginAndRegisterModal
              }, [
                _cache[4] || (_cache[4] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                (rowIndex.value === 4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Log in/sign up"))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_unref(NPopover), {
                  show: showPopover.value,
                  class: "custom-popover",
                  placement: "bottom-end",
                  trigger: "manual",
                  width: 224,
                  "show-arrow": false,
                  onClickoutside: _handleCloseUserPopover,
                  to: ".login-done-container"
                }, {
                  trigger: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "login-done-trigger",
                      onClick: _handleShowUserPopover
                    }, [
                      (userInfo.value?.image)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: userInfo.value?.image
                          }, null, 8, _hoisted_8))
                        : (_openBlock(), _createElementBlock("img", _hoisted_9)),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(userInfo.value?.nickname), 1)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, "Hello, " + _toDisplayString(userInfo.value?.nickname), 1)
                        ]),
                        _createElementVNode("div", {
                          class: "list-item-control",
                          onClick: _handleGoToMyProfile
                        }, _cache[5] || (_cache[5] = [
                          _createElementVNode("span", null, "Your Profile", -1)
                        ])),
                        _createElementVNode("div", {
                          class: "list-item-control line-top",
                          onClick: _handleLoginOut
                        }, _cache[6] || (_cache[6] = [
                          _createElementVNode("span", null, "Log Out", -1)
                        ]))
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["show"])
              ]))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(NModal), {
      show: isShowLoginAndRegisterModal.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((isShowLoginAndRegisterModal).value = $event)),
      "mask-closable": false,
      to: "#app"
    }, {
      default: _withCtx(() => [
        _createVNode(LoginAndRegister, { onCloseLoginAndRegisterModal: closeLoginAndRegisterModal })
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_unref(NModal), {
      show: isShowDoctorAuthenticationModal.value,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((isShowDoctorAuthenticationModal).value = $event)),
      "mask-closable": false,
      to: ".title-bar"
    }, {
      default: _withCtx(() => [
        _createVNode(DoctorAuthentication, { onCloseDoctorAuthenticationModal: closeDoctorAuthenticationModal })
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(PromptModal, {
      show: showConfirmLoginOutModal.value,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((showConfirmLoginOutModal).value = $event)),
      options: {
                showClose: false,
                title: 'Are you sure you want to log out?',
                confirmBtnText: 'Sign Out',
            },
      onOnConfirm: _onConfirmLoginOut
    }, null, 8, ["show"])
  ], 2))
}
}

})