/**
 * tool function
 */

/**
 * validate email
 * @param email
 * @returns
 */
export function validateEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
}

/**
 * validate password
 * @param password
 * @returns
 */
export function validatePassword(password: string) {
    //const passRegex = /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{6,16}$/;
    const passRegex = /^([A-Za-z0-9]).{6,25}$/;
    return passRegex.test(password);
}

/**
 * validate login password
 * @param name
 * @returns
 */
export function validateLoginPassword(name: string) {
    const regex = /^([A-Za-z0-9]).{6,25}$/;
    return regex.test(name);
}

/**
 * validate userName
 * @param password
 * @returns
 */
export function validateUserName(name: string) {
    const regex = /^[a-zA-Z0-9_-]{5,30}$/;
    return regex.test(name);
}

/**
 * 特殊字符转换
 * @param input
 * @returns
 */
export function convertUnicode(input: string) {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) =>
        String.fromCharCode(parseInt(b, 16))
    );
}

/**
 * url截取文件名已文件后缀
 * @param urlPath
 * @returns
 */
export function getFilenameFromUrl(urlPath: string) {
    if (!urlPath) {
        return null;
    }
    const url: any = new URL(urlPath);
    const pathname: string = url.pathname;
    const result = pathname.match(/.*\/(.+)$/);
    return result ? result[1] : null;
}

/**
 * 判断是否是图片文件格式
 * @param fileType
 * @returns
 */
export function isImageFileType(fileType: string) {
    const regex = /(\.|\/)(jpg|jpeg|png|gif|bmp|webp)$/i;
    return regex.test(fileType);
}

/**
 * 判断是否是视频文件格式
 * @param fileType
 * @returns
 */
export function isVideoFileType(fileType: string) {
    const regex = /(\.|\/)(mp4|avi|mpg|mov|wmv|flv|webm)$/i;
    return regex.test(fileType);
}

/**
 * 获取数组里的随机个数
 * @param arr
 * @param count
 * @returns
 */
export function getRandomElements(arr: any[], count: number) {
    const result = new Array(count);
    const len = arr.length;
    const taken = new Set();
    for (let i = 0; i < count; i++) {
        let index;
        do {
            index = Math.floor(Math.random() * len);
        } while (taken.has(index));
        taken.add(index);
        result[i] = arr[index];
    }
    return result;
}

/**
 * 去掉字符的前后空格
 * @param str
 * @returns
 */
export function trim(str: string) {
    return str.replace(/^\s+|\s+$/g, "");
}

/**
 * 判断字符尾部是否有空格
 * @param str
 * @returns
 */
export function endWithSpace(str: string) {
    return /\s$/.test(str);
}

/**
 * 判断字符包含空格
 * @param str
 * @returns
 */
export function strWithSpace(str: string) {
    return /\s/g.test(str);
}

/**
 * 防抖函数
 * @param func
 * @param delay
 * @returns
 */
export function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number
): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    return function (this: any, ...args: Parameters<T>): void {
        clearTimeout(timeout!);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}
