import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/icon_input_clear_plain.svg'
import _imports_1 from '@/assets/images/layout/next_icon.png'
import _imports_2 from '@/assets/icons/icon_layout_eye.svg'
import _imports_3 from '@/assets/icons/icon_layout_password.svg'
import _imports_4 from '@/assets/images/layout/big_app_logo.png'


const _hoisted_1 = { class: "register-message-cont" }
const _hoisted_2 = {
  key: 0,
  class: "email-address-input-cont"
}
const _hoisted_3 = { class: "next-btn-cont" }
const _hoisted_4 = {
  key: 1,
  class: "verify-identity-cont"
}
const _hoisted_5 = {
  key: 2,
  class: "account-message-input-cont"
}
const _hoisted_6 = {
  key: 0,
  src: _imports_2
}
const _hoisted_7 = {
  key: 1,
  src: _imports_3
}
const _hoisted_8 = {
  key: 0,
  src: _imports_2
}
const _hoisted_9 = {
  key: 1,
  src: _imports_3
}
const _hoisted_10 = { class: "create-account-btn-cont" }
const _hoisted_11 = {
  key: 3,
  class: "complete-personal-information"
}
const _hoisted_12 = { class: "personal-preference-procedure" }
const _hoisted_13 = { class: "procedure-list-cont" }
const _hoisted_14 = { class: "procedure-list-wrapper" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "img-cont" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "procedure-text" }
const _hoisted_19 = { class: "btn-cont" }
const _hoisted_20 = { class: "text" }
const _hoisted_21 = {
  key: 4,
  class: "register-done"
}

import {
    NForm,
    NFormItem,
    NInput,
    NButton,
    NRadioGroup,
    NRadio,
    NIcon,
    NScrollbar,
} from "naive-ui";
import type { FormInst, FormItemRule, FormRules } from "naive-ui";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { MessageAlert } from "@/composables/useMessageAlert";
// import ChoseUserIdentify from "./ChoseUserIdentify.vue";
import {
    GenderTypeList,
    REQUEST_SCCUESS_CODE,
    USER_INDENTIFY_KEY,
    UserRole,
} from "@/constant";
import {
    validateEmail,
    validatePassword,
    validateUserName,
} from "@/utils/util";
import { useStore } from "vuex";
import type {
    FormItemThemeOverrides,
    RadioThemeOverrides,
} from "@/types/themeOverrides";
import DateSelect from "@/components/DateSelect.vue";
import { useRegisterAct } from "@/composables/useRegisterAct";
import { useInterestedList } from "@/composables/useInterestedList";
import storage from "@/utils/storage";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterMain',
  props: {
    currentStep: {
        type: Number,
        default: () => {
            return 1;
        },
    },
    curBaseInfoStep: {
        type: Number,
        default: () => {
            return 1;
        },
    },
},
  emits: [
    "update:currentStep",
    "onLogin",
    "onChangeuserInfoStep",
    "onClose",
],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const formItemThemeOverrides: FormItemThemeOverrides = {
    feedbackPadding: "12px 0",
};

const radioItemThemeOverrides: RadioThemeOverrides = {
    textColor: "rgba(0, 0, 0, 0.20)",
    buttonTextColor: "rgba(0, 0, 0, 0.80)",
    dotColorActive: "#2A8CFF",
};

const store = useStore();
const router = useRouter();
const {
    _clickVerifyEmailByApi,
    _userSetTheirProfileByApi,
    _isConfirmEmailByApi,
} = useRegisterAct();
const { curInterestedList, _initInterestedPage } = useInterestedList();
const registerEmailFormRef = ref<FormInst | null>(null);
const createAccountStep = ref<number>(1);
const genderOtpsList = ref(GenderTypeList);
const showRegisterPassword = ref<boolean>(false);
const showRegisterConfirmPassword = ref<boolean>(false);
const registerEmail = ref<any>({
    email: "",
});
const registerEmailRules: FormRules = {
    email: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateEmail(value)) {
                    return new Error("Please enter a valid email address");
                }
                return true;
            },
        },
    ],
};
const cacheUserRole = ref<string>("USER");

const showRegisterEmailClearBtn = computed(() => {
    return registerEmail.value.email ? true : false;
});

const isDisabledRegisterEmailBtn = computed(() => {
    return registerEmail.value.email ? false : true;
});

const registerFormRef = ref<FormInst | null>(null);
const registerMessageModel = ref<any>({
    nickname: null,
    password: null,
    confirmPassword: null,
});

const showRegisterUserNameClearBtn = computed(() => {
    return registerMessageModel.value.nickname ? true : false;
});

const showRegisterPasswordClearBtn = computed(() => {
    return registerMessageModel.value.password ? true : false;
});

const showRegisterConfirmPasswordClearBtn = computed(() => {
    return registerMessageModel.value.confirmPassword ? true : false;
});

const isDisabledCreateAccount = computed(() => {
    return registerMessageModel.value.nickname &&
        registerMessageModel.value.password &&
        registerMessageModel.value.confirmPassword
        ? false
        : true;
});

const registerMessageModelRules: FormRules = {
    nickname: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateUserName(value)) {
                    return new Error("user name must least 5~30 letters");
                }
                return true;
            },
        },
    ],
    password: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validatePassword(value)) {
                    return new Error("password must 6~25 letters and numbers");
                }
                return true;
            },
        },
    ],
    confirmPassword: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validatePassword(value)) {
                    return new Error("password must 6~25 letters and numbers");
                }
                return true;
            },
        },
    ],
};

const curCompletePersonalInformationStep = ref<number>(1);
const completePersonalInformationFormRef = ref<FormInst | null>(null);
const completePersonalInformationModel = ref<any>({
    gender: "0",
    userName: null,
    birthday: null,
    procedureSelected: [],
});

const isDisabledUserMemberPublish = computed(() => {
    return completePersonalInformationModel.value.userName ||
        completePersonalInformationModel.value.procedureSelected
        ? false
        : true;
});
const completePersonalInformationModelRules: FormRules = {
    gender: [
        {
            required: false,
        },
    ],
    userName: [
        {
            required: false,
        },
    ],
    procedureSelected: [
        {
            required: false,
        },
    ],
};

const onSelectBirthady = (date: { year: any; month: any; day: any }) => {
    const { year, month, day } = date;
    completePersonalInformationModel.value.birthday = `${year}-${month}-${day}`;
};

const _handleClearRegisterEmail = () => {
    registerEmail.value.email = "";
};

const _handleValidEmail = () => {
    if (isDisabledRegisterEmailBtn.value) {
        MessageAlert("Please input correct email");
        return;
    }
    //createAccountStep.value = 2;
    registerEmailFormRef.value?.validate(async (errors) => {
        if (!errors) {
            const params: any = {
                email: registerEmail.value.email,
                userRole: cacheUserRole.value,
            };
            const res: any = await _clickVerifyEmailByApi(params);
            if (res?.code === REQUEST_SCCUESS_CODE) {
                //下一步
                createAccountStep.value = 2;
            } else {
                let errMess = res?.msg || "email verify failed";
                MessageAlert(errMess);
            }
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please enter a valid email address";
            MessageAlert(errMess);
        }
    });
};

const _hanleResendEmail = () => {
    _handleValidEmail();
};

const _hanleCheckVerify = async () => {
    const params: any = {
        email: registerEmail.value.email,
        userRole: cacheUserRole.value,
    };
    //createAccountStep.value = 3;
    const res: any = await _isConfirmEmailByApi(params);
    if (res?.code === REQUEST_SCCUESS_CODE) {
        //下一步
        createAccountStep.value = 3;
    } else {
        let errMess = res?.msg || "email confirm failed";
        MessageAlert(errMess);
    }
};

const _handleClearRegisterUserName = () => {
    registerMessageModel.value.nickname = "";
};

const _handleClearRegisterPassword = () => {
    registerMessageModel.value.password = "";
};

const _handleClearRegisterConfirmPassword = () => {
    registerMessageModel.value.confirmPassword = "";
};

const _handleShowRegisterPassword = () => {
    showRegisterPassword.value = !showRegisterPassword.value;
};

const _handleShowRegisterConfirmPassword = () => {
    showRegisterConfirmPassword.value = !showRegisterConfirmPassword.value;
};

const updateCreateAccountStep = (step: number) => {
    emits("update:currentStep", step);
};

const updateCompleteInfoStep = (step: number) => {
    emits("onChangeuserInfoStep", step);
};

const _handleCreateAccount = () => {
    if (isDisabledCreateAccount.value) {
        MessageAlert("Please enter");
        return;
    }
    if (
        registerMessageModel.value.password !==
        registerMessageModel.value.confirmPassword
    ) {
        MessageAlert("confirmPassword must be consistent");
        return;
    }
    //createAccountStep.value = 4;
    registerFormRef.value?.validate(async (errors) => {
        if (!errors) {
            try {
                const userType = storage.get(USER_INDENTIFY_KEY) || 1;
                const params = {
                    email: registerEmail.value.email,
                    nickname: registerMessageModel.value.nickname,
                    password: registerMessageModel.value.password,
                    userRole:
                        Number(userType) === 1
                            ? UserRole.User
                            : UserRole.Doctor,
                };
                await store.dispatch("user/userRegisterEmail", params);
                createAccountStep.value = 4;
            } catch (error: any) {
                const errMess = error?.message || "register failed";
                MessageAlert(errMess);
            }
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please enter a valid email address";
            MessageAlert(errMess);
        }
    });
};

watch(
    () => props.currentStep,
    (newV) => {
        createAccountStep.value = newV;
    },
    { immediate: true }
);

watch(
    () => createAccountStep.value,
    (newV) => {
        updateCreateAccountStep(newV);
    },
    { immediate: true }
);

watch(
    () => props.curBaseInfoStep,
    (newV) => {
        curCompletePersonalInformationStep.value = newV;
    },
    { immediate: true }
);

watch(
    () => curCompletePersonalInformationStep.value,
    (newV) => {
        updateCompleteInfoStep(newV);
    },
    { immediate: true }
);

// 注册-选择完职业之后点击next按钮
// const _handleChooseCareerNext = (type: number) => {
//     if (type === 1) {
//         createAccountStep.value = 4;
//     } else if (type === 2) {
//         createAccountStep.value = 6;
//     }
// };

//注册账号-完善个人信息时跳过
const skipCompletePersonalInformation = () => {
    if (curCompletePersonalInformationStep.value === 1) {
        curCompletePersonalInformationStep.value = 2;
    } else {
        createAccountStep.value++;
    }
};

const _handleShowLogin = () => {
    emits("onLogin");
    createAccountStep.value = 1;
};

const _handleRefreshInterestedList = () => {
    _initInterestedPage();
};

const _hancleSelcetProcedureItem = (item: any) => {
    const findIdx =
        completePersonalInformationModel.value.procedureSelected.findIndex(
            (ele: any) => ele === item?.id
        );
    if (findIdx > -1) {
        completePersonalInformationModel.value.procedureSelected.splice(
            findIdx,
            1
        );
    } else {
        completePersonalInformationModel.value.procedureSelected.push(item?.id);
    }
};

const _goToPageDoctorProfile = () => {
    router.push({ path: "/user/profile", query: { from: "user" } });
};

const _handleGoToMyProfilePage = () => {
    _goToPageDoctorProfile();
    nextTick(() => {
        emits("onClose");
    });
};

const _handlePublish = () => {
    //createAccountStep.value = 6;
    completePersonalInformationFormRef.value?.validate(async (errors) => {
        if (!errors) {
            const params = {
                email: registerEmail.value.email,
                nickname: completePersonalInformationModel.value.userName,
                birthday: completePersonalInformationModel.value.birthday,
                gender: completePersonalInformationModel.value.gender,
                interested:
                    completePersonalInformationModel.value.procedureSelected,
            };
            const res: any = await _userSetTheirProfileByApi(params);
            if (res?.code === REQUEST_SCCUESS_CODE) {
                createAccountStep.value = 6;
            } else {
                const errMess = res?.msg || "api failed";
                MessageAlert(errMess);
            }
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please check errors";
            MessageAlert(errMess);
        }
    });
};

const _initRegisterPage = () => {
    const cacheUserRoleLocal = store.getters["user/getUserRole"];
    cacheUserRole.value = cacheUserRoleLocal;

    const cacheUserThirdEmail = store.getters["user/getUserThirdEmail"];
    registerEmail.value.email = cacheUserThirdEmail;
};

onMounted(() => {
    nextTick(() => {
        _initRegisterPage();
    });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (createAccountStep.value === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(NForm), {
            ref_key: "registerEmailFormRef",
            ref: registerEmailFormRef,
            model: registerEmail.value,
            rules: registerEmailRules
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(NFormItem), {
                path: "email",
                label: "Email Address",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: registerEmail.value.email,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((registerEmail.value.email) = $event)),
                    placeholder: "Enter your Email Address",
                    size: "large",
                    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearRegisterEmail
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createElementVNode("img", { src: _imports_0 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showRegisterEmailClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(NButton), {
              color: 
                        !isDisabledRegisterEmailBtn.value
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    ,
              "text-color": 
                        !isDisabledRegisterEmailBtn.value ? '#88EEFF' : '#ffffff'
                    ,
              "icon-placement": "right",
              disabled: isDisabledRegisterEmailBtn.value,
              onClick: _handleValidEmail
            }, {
              icon: _withCtx(() => _cache[12] || (_cache[12] = [
                _createElementVNode("img", {
                  style: {"margin-left":"12px","width":"26px","height":"14px"},
                  src: _imports_1
                }, null, -1)
              ])),
              default: _withCtx(() => [
                (isDisabledRegisterEmailBtn.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" Next ")
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" Verify ")
                    ], 64))
              ]),
              _: 1
            }, 8, ["color", "text-color", "disabled"])
          ]),
          _createElementVNode("div", { class: "log-in-tip" }, [
            _cache[13] || (_cache[13] = _createTextVNode(" Already have an account? ")),
            _createElementVNode("span", { onClick: _handleShowLogin }, "Log in")
          ])
        ]))
      : _createCommentVNode("", true),
    (createAccountStep.value === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "has-sended-message" }, [
            _createTextVNode(" We have sent you an email to verify that your email address is "),
            _createElementVNode("span", null, "marketing@charm-life.com")
          ], -1)),
          _createElementVNode("div", { class: "btn-group" }, [
            _createElementVNode("div", {
              class: "custom-btn resend-btn",
              onClick: _hanleResendEmail
            }, " Resend Email "),
            _createElementVNode("div", {
              class: "custom-btn check-btn",
              onClick: _hanleCheckVerify
            }, " Check Verify ")
          ])
        ]))
      : _createCommentVNode("", true),
    (createAccountStep.value === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_unref(NForm), {
            ref_key: "registerFormRef",
            ref: registerFormRef,
            model: registerMessageModel.value,
            rules: registerMessageModelRules
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(NFormItem), {
                path: "nickname",
                label: "User Name",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: registerMessageModel.value.nickname,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((registerMessageModel.value.nickname) = $event)),
                    placeholder: "Enter Your User Name",
                    size: "large",
                    onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearRegisterUserName
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createElementVNode("img", { src: _imports_0 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showRegisterUserNameClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(NFormItem), {
                path: "password",
                label: "Password",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: registerMessageModel.value.password,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((registerMessageModel.value.password) = $event)),
                    type: showRegisterPassword.value ? 'text' : 'password',
                    placeholder: "Enter Your Password",
                    size: "large",
                    onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "24",
                        onClick: _handleShowRegisterPassword
                      }, {
                        default: _withCtx(() => [
                          (showRegisterPassword.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                            : (_openBlock(), _createElementBlock("img", _hoisted_7))
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearRegisterPassword
                      }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createElementVNode("img", { src: _imports_0 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showRegisterPasswordClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value", "type"])
                ]),
                _: 1
              }),
              _createVNode(_unref(NFormItem), {
                path: "confirmPassword",
                label: "Re-enter your Password",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: registerMessageModel.value.confirmPassword,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((registerMessageModel.value.confirmPassword) = $event)),
                    type: 
                            showRegisterConfirmPassword.value ? 'text' : 'password'
                        ,
                    placeholder: "Confirm Your Password",
                    size: "large",
                    onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, {
                    suffix: _withCtx(() => [
                      _createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "24",
                        onClick: _handleShowRegisterConfirmPassword
                      }, {
                        default: _withCtx(() => [
                          (showRegisterConfirmPassword.value)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                            : (_openBlock(), _createElementBlock("img", _hoisted_9))
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createVNode(_unref(NIcon), {
                        class: "btn-icon",
                        size: "16",
                        onClick: _handleClearRegisterConfirmPassword
                      }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createElementVNode("img", { src: _imports_0 }, null, -1)
                        ])),
                        _: 1
                      }, 512), [
                        [_vShow, showRegisterConfirmPasswordClearBtn.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["value", "type"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(NButton), {
              color: 
                        !isDisabledCreateAccount.value
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    ,
              "text-color": 
                        !isDisabledCreateAccount.value ? '#88EEFF' : '#ffffff'
                    ,
              disabled: isDisabledCreateAccount.value,
              onClick: _handleCreateAccount
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("Create")
              ])),
              _: 1
            }, 8, ["color", "text-color", "disabled"])
          ])
        ]))
      : _createCommentVNode("", true),
    (createAccountStep.value === 4)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _withDirectives(_createVNode(_unref(NForm), {
            ref_key: "completePersonalInformationFormRef",
            ref: completePersonalInformationFormRef,
            model: completePersonalInformationModel.value,
            rules: completePersonalInformationModelRules
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(NFormItem), { "theme-overrides": formItemThemeOverrides }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NRadioGroup), {
                    value: completePersonalInformationModel.value.gender,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((completePersonalInformationModel.value.gender) = $event)),
                    name: "radiogroup1"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(genderOtpsList.value, (rad) => {
                        return (_openBlock(), _createBlock(_unref(NRadio), {
                          key: rad.value,
                          value: rad.value,
                          size: "large",
                          "theme-overrides": radioItemThemeOverrides
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(rad.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(NFormItem), {
                path: "userName",
                label: "Your Name",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(NInput), {
                    value: 
                            completePersonalInformationModel.value.userName
                        ,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((
                            completePersonalInformationModel.value.userName
                        ) = $event)),
                    placeholder: "Enter Your Name",
                    size: "large",
                    onKeydown: _cache[10] || (_cache[10] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_unref(NFormItem), {
                path: "birthday",
                label: "Birthday",
                "theme-overrides": formItemThemeOverrides
              }, {
                default: _withCtx(() => [
                  _createVNode(DateSelect, {
                    value: completePersonalInformationModel.value.birthday,
                    onOnChange: onSelectBirthady
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]), [
            [_vShow, curCompletePersonalInformationStep.value === 1]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", { class: "refresh-procedure-tip" }, [
              _cache[19] || (_cache[19] = _createTextVNode(" Don’t like these? ")),
              _createElementVNode("span", {
                class: "btn-refresh",
                onClick: _handleRefreshInterestedList
              }, " Refresh ")
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_unref(NScrollbar), { style: {"max-height":"300px"} }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(curInterestedList), (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass(["procedure-list-item", {
                                    selected:
                                        completePersonalInformationModel.value.procedureSelected.includes(
                                            item?.id
                                        ),
                                }]),
                        onClick: ($event: any) => (_hancleSelcetProcedureItem(item))
                      }, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("img", {
                            src: item.img
                          }, null, 8, _hoisted_17)
                        ]),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(item?.name), 1)
                      ], 10, _hoisted_15))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ])
          ], 512), [
            [_vShow, curCompletePersonalInformationStep.value === 2]
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_unref(NButton), {
              "text-color": "#000000",
              style: {"border":"1px solid #000000"},
              onClick: skipCompletePersonalInformation
            }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode(" Skip ")
              ])),
              _: 1
            }),
            _createVNode(_unref(NButton), {
              color: 
                        !isDisabledUserMemberPublish.value
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    ,
              "text-color": 
                        !isDisabledUserMemberPublish.value ? '#88EEFF' : '#ffffff'
                    ,
              disabled: isDisabledUserMemberPublish.value,
              onClick: _handlePublish
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode(" Publish ")
              ])),
              _: 1
            }, 8, ["color", "text-color", "disabled"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["complete-personal-information-line", {
                    'two-complete-personal-information':
                        curCompletePersonalInformationStep.value === 2,
                }])
          }, [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "line" }, [
              _createElementVNode("div", { class: "weight-line" })
            ], -1)),
            _createElementVNode("div", _hoisted_20, _toDisplayString(curCompletePersonalInformationStep.value) + " of 2 ", 1)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (createAccountStep.value === 5 || createAccountStep.value === 6)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _cache[26] || (_cache[26] = _createElementVNode("div", { class: "title" }, "Welcome to Charm Life", -1)),
          _cache[27] || (_cache[27] = _createElementVNode("div", { class: "big-logo-cont" }, [
            _createElementVNode("img", { src: _imports_4 })
          ], -1)),
          (createAccountStep.value === 5)
            ? (_openBlock(), _createBlock(_unref(NButton), {
                key: 0,
                color: "#000000",
                "text-color": "#88EEFF",
                onClick: _handleShowLogin
              }, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode(" Log in ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (createAccountStep.value === 6)
            ? (_openBlock(), _createBlock(_unref(NButton), {
                key: 1,
                color: "#000000",
                "text-color": "#88EEFF",
                "icon-placement": "right",
                onClick: _handleGoToMyProfilePage
              }, {
                icon: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createElementVNode("img", {
                    style: {"margin-left":"12px","width":"26px","height":"14px"},
                    src: _imports_1
                  }, null, -1)
                ])),
                default: _withCtx(() => [
                  _cache[25] || (_cache[25] = _createTextVNode(" Let’s Set Up Your Profile "))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})