import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx } from "vue"

import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import {
    NConfigProvider,
    GlobalThemeOverrides,
    NMessageProvider,
} from "naive-ui";
import MessageContent from "@/views/layout/MessageContent.vue";
import TitleBar from "@/views/layout/TitleBar.vue";
import FooterBar from "@/views/layout/FooterBar.vue";
import { useStore } from "vuex";
import { MessageAlert } from "./composables/useMessageAlert";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();
const store = useStore();
const curRouterIsHome = ref<boolean>(false);
const themeOverrides: GlobalThemeOverrides = {
    common: {
        primaryColor: "#1195F5",
    },
    Button: {
        textColor: "#000000",
    },
    Input: {
        borderHover: "1px solid rgb(224, 224, 230)",
        borderFocus: "1px solid rgb(224, 224, 230)",
        boxShadowFocus: "1px solid rgb(224, 224, 230)",
    },
    Select: {
        peers: {
            InternalSelection: {
                borderHover: "1px solid rgb(224, 224, 230)",
                borderActive: "1px solid rgb(224, 224, 230)",
                borderFocus: "1px solid rgb(224, 224, 230)",
                boxShadowHover: "1px solid rgb(224, 224, 230)",
                boxShadowActive: "1px solid rgb(224, 224, 230)",
                boxShadowFocus: "1px solid rgb(224, 224, 230)",
            },
        },
    },
};

const routerKey = computed(() => {
    return router.currentRoute.value?.fullPath;
});

watch(
    () => router.currentRoute.value,
    (newValue) => {
        //console.log("router.currentRoute===>", newValue, oldValue);
        if (newValue.name === "Home" && newValue.matched.length > 0) {
            curRouterIsHome.value = true;
        } else {
            curRouterIsHome.value = false;
        }
    },
    { immediate: true }
);

const _fetchUserInfoByApi = async () => {
    try {
        await store.dispatch("user/fetchUserInfo");
    } catch (error: any) {
        const errMess = error?.message || "login failed";
        MessageAlert(errMess);
    }
};

onMounted(() => {
    //console.log("reloadApp====>")
    nextTick(() => {
        _fetchUserInfoByApi();
    });
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_unref(NConfigProvider), { "theme-overrides": themeOverrides }, {
    default: _withCtx(() => [
      _createVNode(TitleBar, { "is-home": curRouterIsHome.value }, null, 8, ["is-home"]),
      (routerKey.value.indexOf('/post') > -1)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : (_openBlock(), _createBlock(_component_router_view, { key: routerKey.value })),
      _createVNode(FooterBar),
      _createVNode(_unref(NMessageProvider), {
        placement: "bottom",
        "container-class": "custom-message-dialog"
      }, {
        default: _withCtx(() => [
          _createVNode(MessageContent)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})