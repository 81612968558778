import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/home/video_play_icon.png'
import _imports_1 from '@/assets/icons/icon_video_start_play.svg'
import _imports_2 from '@/assets/images/home/download_scan_qrcode_img.png'


const _hoisted_1 = { class: "home-index" }
const _hoisted_2 = { class: "charm-message-cont section-message-cont" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "btn-cont" }
const _hoisted_5 = { class: "doctor-message-cont section-message-cont" }
const _hoisted_6 = { class: "btn-cont" }
const _hoisted_7 = { class: "post-message-cont section-message-cont" }
const _hoisted_8 = { class: "btn-cont" }
const _hoisted_9 = { class: "other-cosmetic-procedures section-message-cont" }
const _hoisted_10 = { class: "cosmetic-procedures-cont" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "btn-cont" }
const _hoisted_13 = { class: "download-message-cont section-message-cont" }

import { NButton, NCarousel, NIcon } from "naive-ui";
import { computed, nextTick, onMounted, onUnmounted, ref } from "vue";
import { ProcedureTransMockList } from "@/mock/MockList";
import VideoPreviewModal from "@/components/VideoPreviewModal.vue";
import { useRouter } from "vue-router";
import videoUrl from "@/assets/images/home/App-Demo-V10.mp4";
import videoH5Url from "@/assets/images/home/App-Demo-H5.mp4";
import { BannersList } from "@/constant";
import { getRandomElements } from "@/utils/util";


export default /*@__PURE__*/_defineComponent({
  __name: 'homeIndex',
  setup(__props) {

const router = useRouter();
const otherCosmeticProceduresList = ref<any>(ProcedureTransMockList);
const screenMinWidth = ref<number>(1440);
const calcDoctorImgContWidth = ref<number>(480);
const showVideoPreviewModal = ref<boolean>(false);
const videoPreviewOpts = {
    showEdit: false,
    src: videoUrl,
};
const videoH5PreviewOpts = {
    showEdit: false,
    src: videoH5Url,
};
const curVideoPreivewOpts = ref(videoPreviewOpts);

const calcDoctorImgContHeightObj = computed(() => {
    return {
        height: (calcDoctorImgContWidth.value * 619) / 480 + "px",
    };
});
const curBannerList = computed(() => {
    const list = getRandomElements(BannersList, 3);
    return list;
});

// 计算屏幕大小
const calcScreenMinWidth = (vWidth: number) => {
    if (vWidth >= 1440) {
        screenMinWidth.value = 1440;
    }
    if (1020 <= vWidth && vWidth < 1440) {
        screenMinWidth.value = 1020;
    }
    if (vWidth < 1020) {
        screenMinWidth.value = 744;
    }
};

const _onShowVideoPreviewModal = () => {
    showVideoPreviewModal.value = !showVideoPreviewModal.value;
};

const _handleShowCharmVideo = () => {
    curVideoPreivewOpts.value = videoPreviewOpts;
    nextTick(() => {
        _onShowVideoPreviewModal();
    });
};

const _handleShowCharmH5Video = () => {
    curVideoPreivewOpts.value = videoH5PreviewOpts;
    nextTick(() => {
        _onShowVideoPreviewModal();
    });
};

const _goToDoctorsHomePage = () => {
    router.push({
        path: "/doctor",
    });
};

const _goToPostHomePage = () => {
    router.push({
        path: "/post",
    });
};

const _goToProcedureHomePage = () => {
    router.push({
        path: "/procedure",
    });
};

onMounted(() => {
    calcScreenMinWidth(window.innerWidth);

    calcDoctorImgContWidth.value = window.innerWidth / 3;

    window.addEventListener("resize", () => {
        calcScreenMinWidth(window.innerWidth);

        calcDoctorImgContWidth.value = window.innerWidth / 3;
    });
});

onUnmounted(() => {
    window.removeEventListener("resize", () => {
        calcScreenMinWidth(window.innerWidth);
    });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(NCarousel), {
        autoplay: "",
        interval: 3000,
        "show-dots": true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(curBannerList.value, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "img-cont"
            }, [
              _createElementVNode("img", {
                src: item.imgSrc,
                alt: "bannner"
              }, null, 8, _hoisted_3)
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"loading-bar-cont\" data-v-f91cde56><div class=\"loading-bar\" data-v-f91cde56></div></div><div class=\"message-cont\" data-v-f91cde56><div class=\"message-title\" data-v-f91cde56> Charm Community For All Beauty Lovers </div><div class=\"message-describe\" data-v-f91cde56> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </div></div>", 2)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(NButton), {
          color: "#000000",
          onClick: _handleShowCharmVideo
        }, {
          icon: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              style: {"width":"32px","height":"32px"},
              src: _imports_0
            }, null, -1)
          ])),
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createTextVNode(" What is Charm? "))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "img-cont",
        style: _normalizeStyle(calcDoctorImgContHeightObj.value)
      }, _cache[4] || (_cache[4] = [
        _createStaticVNode("<div class=\"img-item\" data-v-f91cde56><div class=\"img-item-message\" data-v-f91cde56><span data-v-f91cde56>Ut enim ad min</span></div></div><div class=\"img-item\" data-v-f91cde56><div class=\"img-item-message\" data-v-f91cde56><span data-v-f91cde56>Ut enim ad min</span></div></div><div class=\"img-item\" data-v-f91cde56><div class=\"img-item-message\" data-v-f91cde56><span data-v-f91cde56>Ut enim ad min</span></div></div>", 3)
      ]), 4),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "message-cont" }, [
        _createElementVNode("div", { class: "message-title" }, "Our Doctors"),
        _createElementVNode("div", { class: "message-describe" }, " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ")
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(NButton), {
          color: "#000000",
          onClick: _goToDoctorsHomePage
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Consult a doctor ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "img-cont" }, null, -1)),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "message-cont" }, [
        _createElementVNode("div", { class: "message-title" }, "Share Your Posts"),
        _createElementVNode("div", { class: "message-describe" }, " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ")
      ], -1)),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_unref(NButton), {
          color: "#000000",
          onClick: _goToPostHomePage
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" View More Posts ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "message-cont" }, [
        _createElementVNode("div", { class: "message-title" }, "Other Cosmetic Procedures")
      ], -1)),
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(otherCosmeticProceduresList.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "cosmetic-procedures-item"
          }, [
            _createElementVNode("img", {
              src: item.imgSrc
            }, null, 8, _hoisted_11),
            _createElementVNode("div", null, _toDisplayString(item.name), 1)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_unref(NButton), {
          color: "#000000",
          onClick: _goToProcedureHomePage
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" View All Procedures ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", {
        class: "phone-cont",
        onClick: _handleShowCharmH5Video
      }, [
        _createVNode(_unref(NIcon), {
          class: "btn-icon",
          size: "60"
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createElementVNode("img", { src: _imports_1 }, null, -1)
          ])),
          _: 1
        })
      ]),
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "qrcode-cont" }, [
        _createElementVNode("div", { class: "title" }, "Try Charm"),
        _createElementVNode("div", { class: "tip" }, "Scan to download"),
        _createElementVNode("img", { src: _imports_2 })
      ], -1))
    ]),
    _createVNode(VideoPreviewModal, {
      show: showVideoPreviewModal.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showVideoPreviewModal).value = $event)),
      options: curVideoPreivewOpts.value,
      to: ".home-index"
    }, null, 8, ["show", "options"])
  ]))
}
}

})