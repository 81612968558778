/**
 * constant varibale
 */

export const LOGIN_TOKEN_KEY = "login-token";
export const USER_INDENTIFY_KEY = "accountType";
export const USER_ROLE_KEY = "userRole";
export const USER_INFO_KEY = "userInfo";
export const USER_THIRD_LOGIN_INFO_KEY = "userThirdLoginInfo";

export const REQUEST_SCCUESS_CODE = 100;
export const REQUEST_SCCUESS_STATUS = "Success";

export const VUE_APP_IMG_BUCKET_NAME = "charm-post-img";
export const VUE_APP_IMG_BUCKET_REGION = "us-west-1";
export const MAX_FILE_SIZE = 100000 * 1024;
export const GOOGLE_CLIENTID =
    "1034793734928-td2qeg4g3rsjesij5rfqg00a4hs5egjp.apps.googleusercontent.com";

export enum UserRole {
    User = "USER",
    Doctor = "DOCTOR",
}

export const WeekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const EnglishMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const MenuTabsList = [
    {
        title: "Home",
        name: "Home",
    },
    {
        title: "Doctors",
        name: "Doctors",
    },
    {
        title: "Posts",
        name: "Posts",
    },
    {
        title: "Procedures",
        name: "Procedures",
    },
    {
        title: "Instruments",
        name: "Instruments",
    },
];

export enum SimpleLoginType {
    Google = "google",
    Apple = "apple",
    Facebook = "facebook",
}

export const ProcedureImgList = [
    {
        imgSrc: require("@/assets/images/procedure/types/botox_injections.svg"),
        titleName: "Botox Injection",
        categoryName: "botox_injections",
        Cost: "$300–$600 per area.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/breast_augmentation.svg"),
        titleName: "Breast Augmentation",
        categoryName: "breast_augmentation",
        Cost: "AUD $8,000–$15,000",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/chemical_peels.svg"),
        titleName: "Chemical Peels",
        categoryName: "chemical_peels",
        Cost: "AUD $150–$500 per session, depending on the type of peel.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/chin_implants.svg"),
        titleName: "Chin Implants",
        categoryName: "chin_implants",
        Cost: "AUD $4,000–$8,000.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/fox_eyes.svg"),
        titleName: "Fox Eyes",
        categoryName: "fox_eyes",
        Cost: "AUD $1,500–$8,000, depending on the procedure type (non-surgical or surgical).",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/facelift.svg"),
        titleName: "Face lift",
        categoryName: "facelift",
        Cost: "Facelift surgery costs generally range from AUD $10,000 to $20,000, depending on the complexity of the procedure.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/laser_hair_removal.svg"),
        titleName: "Laser Hair Removal",
        categoryName: "laser_hair_removal",
        Cost: "AUD $100–$600 per session, depending on the size of the area being treated.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/lip_augmentation.svg"),
        titleName: "Lip augmentation",
        categoryName: "lip_augmentation",
        Cost: "AUD $400–$2,500, depending on the treatment type and the amount of filler required.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/otoplasty.svg"),
        titleName: "Otoplasty",
        categoryName: "otoplasty",
        Cost: " Surgical neck lifts typically cost between AUD $5,000–$10,000, with non-surgical options generally being more affordable.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/neck_contouring.svg"),
        titleName: "Neck Contouring",
        categoryName: "neck_contouring",
        Cost: "The cost of otoplasty ranges from AUD $4,000 to $8,000, depending on the complexity and location of the surgery.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/teeth_whitening.svg"),
        titleName: "Teeth Whitening",
        categoryName: "teeth_whitening",
        Cost: "In-office treatment: AUD $500–$1000, Take-home kits: AUD $300–$600, Over-the-counter products: AUD $20–$100.",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/tummy_tuck.svg"),
        titleName: "Tummy Tuck",
        categoryName: "tummy_tuck",
        Cost: "The cost of a tummy tuck typically ranges from AUD $7,000 to $15,000, depending on the type of surgery, location, and surgeon’s expertise.",
    },
];

export const PaginationPageSizesList = [
    {
        label: "10 Page",
        value: 10,
    },
    {
        label: "20 Page",
        value: 20,
    },
];

export const GenderTypeList = [
    {
        label: "Male",
        value: "1",
    },
    {
        label: "Female",
        value: "2",
    },
    {
        label: "Other",
        value: "3",
    },
];

export const InstrumentTypeList = [
    {
        imgSrc: require("@/assets/images/instrument/thermage.svg"),
        name: "thermage",
        categoryName: "Thermage",
    },
    {
        imgSrc: require("@/assets/images/instrument/inmode.svg"),
        name: "inmode",
        categoryName: "Inmode",
    },
    {
        imgSrc: require("@/assets/images/instrument/coolsculpting.svg"),
        name: "coolsculpting",
        categoryName: "Coolsculpting",
    },
    {
        imgSrc: require("@/assets/images/instrument/fraxel_laser.svg"),
        name: "fraxel_laser",
        categoryName: "Fraxel Laser",
    },
];

export const TitlePositionTypeList = [
    {
        label: "Medical Student",
        value: "Medical Student",
    },
    {
        label: "Intern",
        value: "Intern",
    },
    {
        label: "Resident",
        value: "Resident",
    },
    {
        label: "Physician",
        value: "Physician",
    },
];

export const EducationalBackgroundList = [
    {
        label: "Doctors-in-training",
        value: "Doctors-in-training",
    },
    {
        label: "Foundation doctor",
        value: "Foundation doctor",
    },
    {
        label: "Specialty Registrar",
        value: "Specialty Registrar",
    },
    {
        label: "Middle-grade doctors",
        value: "Middle-grade doctors",
    },
    {
        label: "Staff grade doctor",
        value: "Staff grade doctor",
    },
    {
        label: "Specialty doctors",
        value: "Specialty doctors",
    },
    {
        label: "Associate specialist doctor",
        value: "Associate specialist doctor",
    },
    {
        label: "Consultants",
        value: "Consultants",
    },
];

export const DistanceOptionsList = [
    {
        label: "All",
        value: "0",
    },
    {
        label: "Distance",
        value: "1",
    },
    {
        label: "Shortest Distance",
        value: "2",
    },
    {
        label: "Recommend",
        value: "3",
    },
];

export const VerificateOptionsList = [
    {
        label: "All",
        value: "0",
    },
    {
        label: "Verified",
        value: "1",
    },
    {
        label: "Unverified",
        value: "2",
    },
];

export const SatisfactionRateOptionsList = [
    {
        label: "0 star",
        value: "0",
    },
    {
        label: "1 star",
        value: "1",
    },
    {
        label: "2 star",
        value: "2",
    },
    {
        label: "3 star",
        value: "3",
    },
    {
        label: "4 star",
        value: "4",
    },
    {
        label: "5 star",
        value: "5",
    },
];

export const LocationList = [
    {
        label: "NSW-Sydney-AU",
        value: "nsw_sydney_au",
    },
    {
        label: "Victoria-Melbourne-AU",
        value: "victoria_melbourne_au",
    },
    {
        label: "QLD-Brisbane-AU",
        value: "qld_brisbane_au",
    },
    {
        label: "WA-Perth-AU",
        value: "wa_perth_au",
    },
    {
        label: "Miami-Florida-USA",
        value: "miami_florida_usa",
    },
    {
        label: "Atlanta-Georgia-USA",
        value: "atlanta_georgia_usa",
    },
    {
        label: "Dallas-Texas-USA",
        value: "dallas_texas_usa",
    },
    {
        label: "SanFrancisco-California-USA",
        value: "sanFrancisco_california_usa",
    },
    {
        label: "Sacramento-California-USA",
        value: "sacramento_california_usa",
    },
];

export const categoryOptionsList = (() => {
    const list = ProcedureImgList.map((item: any) => {
        return {
            label: item.titleName,
            value: item.categoryName,
        };
    });
    return list;
})();

export const BannersList = [
    {
        id: 1,
        imgSrc: require("@/assets/images/home/banner/defkfefff_A_beautiful_woman_with_distinct_facial_features_a_clo_4.png"),
    },
    {
        id: 2,
        imgSrc: require("@/assets/images/home/banner/defkfefff_A_close-up_of_an_aestheticians_hand_holding_and_opera_1.png"),
    },
    {
        id: 3,
        imgSrc: require("@/assets/images/home/banner/defkfefff_A_close-up_of_the_face_with_an_oil_drop_on_one_side_a_2.png"),
    },
    {
        id: 4,
        imgSrc: require("@/assets/images/home/banner/defkfefff_A_close-up_of_the_face_with_clear_skin_and_visible_te_6.png"),
    },
    {
        id: 5,
        imgSrc: require("@/assets/images/home/banner/defkfefff_A_close-up_shot_of_the_womans_face_with_one_eye_open__9.png"),
    },
    {
        id: 6,
        imgSrc: require("@/assets/images/home/banner/defkfefff_A_pale_red-haired_woman_in_her_late_thirties_with_blu_6.png"),
    },
    {
        id: 7,
        imgSrc: require("@/assets/images/home/banner/defkfefff_A_young_woman_is_receiving_a_facial_treatment_in_a_be_e.png"),
    },
    {
        id: 8,
        imgSrc: require("@/assets/images/home/banner/defkfefff_Photograph_of_a_model_with_flawless_translucent_skin__8.png"),
    },
    {
        id: 9,
        imgSrc: require("@/assets/images/home/banner/aurora_clark7208_A_skincare_editorial_shoot_featuring_four_dive_b565af85-9d6f-4713-924c-a8035242b9eb.png"),
    },
    {
        id: 10,
        imgSrc: require("@/assets/images/home/banner/aurora_clark7208_Three_diverse_models_laughing_and_smiling_show_3-1.png"),
    },
];

export const MED_BODYAREA_LIST = [
    {
        id: 1,
        label: "Breast",
        values: "Breast",
    },
    {
        id: 2,
        label: "Facial",
        values: "Facial",
    },
    {
        id: 3,
        label: "Body",
        values: "Body",
    },
    {
        id: 4,
        label: "Skin",
        values: "Skin",
    },
    {
        id: 5,
        label: "Nose",
        values: "Nose",
    },
    {
        id: 6,
        label: "Eyes",
        values: "Eyes",
    },
    {
        id: 7,
        label: "Forehead",
        values: "Forehead",
    },
    {
        id: 8,
        label: "Neck",
        values: "Neck",
    },
    {
        id: 9,
        label: "Hands",
        values: "Hands",
    },
    {
        id: 10,
        label: "Abdomen",
        values: "Abdomen",
    },
    {
        id: 11,
        label: "Arms",
        values: "Arms",
    },
    {
        id: 12,
        label: "Jaw",
        values: "Jaw",
    },
    {
        id: 13,
        label: "Fat areas",
        values: "Fat areas",
    },
    {
        id: 14,
        label: "Thighs",
        values: "Thighs",
    },
    {
        id: 15,
        label: "Buttocks",
        values: "Buttocks",
    },
    {
        id: 16,
        label: "Chin",
        values: "Chin",
    },
];
