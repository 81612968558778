/**
 * doctor api: doctor-controller | doctor-application-controller
 */
import type { AppDoctorRequest } from "@/types";
import httpRequest from "@/utils/request";

/**
 * findDoctorByFilterType
 * @param param
 * @returns
 */
const findDoctorByFilterType = (param: {
    address?: string;
    currentPage?: number;
    pageSize?: number;
    filterType?: string[];
    memberId?: number;
    name?: string;
    nickname?: string;
    searchKey?: string;
    category?: string;
}) => {
    return httpRequest({
        url: "/doctor/search",
        method: "post",
        data: param,
    });
};

/**
 * getAllDoctorsAddressFilter
 * @param param
 * @returns
 */
const getAllDoctorsAddressFilter = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: "/doctor/search/address",
        method: "post",
        data: param,
    });
};

/**
 * getAllProcedure
 * @param param
 * @returns
 */
const getAllProcedure = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: "/doctor/search/procedure",
        method: "post",
        data: param,
    });
};

/**
 * getDoctorResume
 * @param param
 */
const getDoctorResume = (param: { id: string }) => {
    return httpRequest({
        url: `/doctor/search/${param.id}`,
        method: "get",
        params: param,
    });
};

/**
 * topDoctors
 * @param param
 * @returns
 */
const topDoctors = (param: { currentPage: number; pageSize: number }) => {
    return httpRequest({
        url: `/doctor/top-doctors`,
        method: "post",
        data: param,
    });
};

/**
 * applyToBeDoctor
 * @param param
 * @returns
 */
const applyToBeDoctor = (param: AppDoctorRequest) => {
    return httpRequest({
        url: `/doctor-application/apply`,
        method: "post",
        data: param,
    });
};

/**
 * getDoctorsApplying
 * @param param
 * @returns
 */
const getDoctorsApplying = (param: any) => {
    return httpRequest({
        url: `/doctor-application/applying`,
        method: "get",
        params: param,
    });
};

/**
 * updateDoctorApproval
 * @param param
 * @returns
 */
const updateDoctorApproval = (param: {
    member_id: string;
    reject_content: string;
    state: string;
}) => {
    return httpRequest({
        url: `/doctor-application/updateApproval`,
        method: "post",
        data: param,
    });
};

/**
 * getEvaluationsForDoctorById
 * @param param
 * @returns
 */
const getEvaluationsForDoctorById = (param: {
    page: number;
    pageSize: number;
    id: number;
}) => {
    return httpRequest({
        url: `/evaluate/evaluations`,
        method: "get",
        params: param,
    });
};

/**
 * evaluateDetailTheDoctorInReview
 * @param param
 * @returns
 */
const evaluateDetailTheDoctorInReview = (param: {
    memberId: any;
    currentPage: number;
    pageSize: number;
    nickname: string;
}) => {
    return httpRequest({
        url: `/evaluate/evaluations:page`,
        method: "post",
        data: param,
    });
};

/**
 * doctorDetailInfo
 * @param param
 * @returns
 */
const doctorDetailInfo = (param: {
    memberId: number;
    currentPage: number;
    pageSize: number;
    nickname: string;
}) => {
    return httpRequest({
        url: `/info/doctor-details`,
        method: "post",
        data: param,
    });
};

/**
 * getDoctorDetailById
 * @param param
 * @returns
 */
const getDoctorDetailById = (param: { id: number }) => {
    return httpRequest({
        url: `/info/doctor-details/${param.id}`,
        method: "get",
        params: param,
    });
};

/**
 * Get all posts by member id
 * @param param
 * @returns
 */
const getAllPostsByMemberId = (param: {
    currentPage?: number;
    pageSize?: number;
    userRole?: [];
    memberId?: string;
}) => {
    return httpRequest({
        url: "/post/all_posts",
        method: "post",
        data: param,
    });
};

/**
 * Get all liked posts by member id
 * @param param
 * @returns
 */
const getLikedPostsByMemberId = (param: {
    currentPage?: number;
    pageSize?: number;
    userRole?: [];
    memberId?: string;
}) => {
    return httpRequest({
        url: "/user_action/likedPosts",
        method: "post",
        data: param,
    });
};

/**
 * add video
 * @param param
 * @returns
 */
const addVideoByMemberId = (param: {
    id?: number;
    title?: string;
    mark?: string;
    videoUrl: string;
}) => {
    return httpRequest({
        url: "/doctor/addVideo",
        method: "post",
        data: param,
    });
};

/**
 * delete video
 * @param param
 * @returns
 */
const deleteVideoById = (param: { id?: number }) => {
    return httpRequest({
        url: `/doctor/deleteVideo/${param?.id}`,
        method: "delete",
        data: param,
    });
};

const getAllVideosByMemberId = (param: {
    currentPage?: number;
    pageSize?: number;
    userRole?: [];
    memberId?: string;
}) => {
    return httpRequest({
        url: "/doctor/videoByMemberId",
        method: "post",
        data: param,
    });
};

const getSavedPostsByMemberId = (param: {
    currentPage?: number;
    pageSize?: number;
    memberId?: string;
}) => {
    return httpRequest({
        url: "/post/savePostList",
        method: "post",
        data: param,
    });
};

const getInboxListByMemberId = (param: {
    currentPage?: number;
    pageSize?: number;
    memberId?: string;
}) => {
    return httpRequest({
        url: "/doctor/doctor-inbox",
        method: "post",
        data: param,
    });
};

const getDoctorAreaList = (param?: any) => {
    return httpRequest({
        url: `/doctor/areaList`,
        method: "get",
        params: param,
    });
};

export default {
    findDoctorByFilterType,
    getAllDoctorsAddressFilter,
    getAllProcedure,
    getDoctorResume,
    topDoctors,
    applyToBeDoctor,
    getDoctorsApplying,
    updateDoctorApproval,
    getEvaluationsForDoctorById,
    evaluateDetailTheDoctorInReview,
    doctorDetailInfo,
    getDoctorDetailById,
    getAllPostsByMemberId,
    getLikedPostsByMemberId,
    addVideoByMemberId,
    deleteVideoById,
    getAllVideosByMemberId,
    getSavedPostsByMemberId,
    getInboxListByMemberId,
    getDoctorAreaList,
};
